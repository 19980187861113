import api from "../../../services/apiCaller";
import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import * as numeral from "numeral";
import "./occupancy.css";
import Spinner from "../../../components/spinner/Spinner";

class OccupancyChart extends Component {
  constructor(props) {
    super(props);
    this.chartReference = React.createRef();
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount = () => {
    this._apiCall();
  };

  _apiCall = async () => {
    try {
      const data = await api.get("partnership/serviced-offices-chart", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return await this.setState({
        isLoading: false,
        data: data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  chartData = () => {
    const data = {
      labels: ["Empty", "Occupied", "Booked"],
      datasets: [
        {
          data: Object.values(this.state.data),
          backgroundColor: [
            "rgba(236, 112, 99, 1)",
            "rgba(66, 165, 245, 1)",
            "rgba(79, 195, 247, 1)",
            "rgba(133, 146, 158, 1)",
          ],
          borderColor: [
            "rgba(236, 112, 99, 1)",
            "rgba(66, 165, 245, 1)",
            "rgba(79, 195, 247, 1)",
            "rgba(133, 146, 158, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    return (
      <>
        {/* <Pie
          ref={this.chartReference}
          data={data}
          options={{
            legend: {
              position: "bottom",
            },
            plugins: {
              datalabels: {
                formatter: function (value, context) {
                  return value ? value : "";
                },
                display: true,
                color: "black",
                font: {
                  weight: "bold",
                  size: 16,
                },
              },
            },
          }}
        /> */}
        <table className="mt-3 occ-table">
          <thead>
            <tr>
              <th scope="col">Condition</th>
              <th scope="col">Rooms</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-label="Product">Reserved</td>
              <td data-label="Rooms">{this.state.data.booked}</td>
            </tr>
            <tr>
              <td data-label="Product">Vacant</td>
              <td data-label="Rooms">{this.state.data.empty}</td>
            </tr>
            <tr>
              <td data-label="Product">Occupied</td>
              <td data-label="Rooms">{this.state.data.occupied}</td>
            </tr>
            <tr>
              <td data-label="Product">
                <strong>Total Rooms</strong>
              </td>
              <td data-label="Rooms" style={{ fontWeight: "700" }}>
                {this.state.data.occupied +
                  this.state.data.booked +
                  this.state.data.empty}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  countPercentage = () => {
    let data = 0;

    Object.values(this.state.data).map((v) => {
      return (data += v);
    });

    return numeral((this.state.data.occupied / data) * 100).format("0,0") + "%";
  };

  render() {
    return (
      <>
        {!this.state.isLoading ? this.chartData() : <Spinner />}
        <label className="mt-1 mb-0" style={{ fontSize: 12, color: "#9C9C9C" }}>
          Occupation Rates
        </label>
        <h6 className="my-0">{this.state.data ? this.countPercentage() : 0}</h6>
      </>
    );
  }
}

export default OccupancyChart;
