import React, { Component } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Header from "../../components/header/Header";
import RevenueChart from "./components/revenue";
import OccupancyChart from "./components/occupancy";
import ClientCardRow from "./components/componentCardRow";
import { IoEye } from "react-icons/io5";
import { Link } from "react-router-dom";
import "./index.css";

const mediaMatch = window.matchMedia("(max-width: 480px)");

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: mediaMatch.matches,
    };
  }

  componentDidMount = () => {
    mediaMatch.addEventListener("resize", (e) => {
      this.state.matches = e.matches;
    });
    return () =>
      mediaMatch.removeEventListener("resize", (e) => {
        this.state.matches = e.matches;
      });
  };

  render() {
    const chartContainer = {
      padding: 20,
      border: "1px solid #E5E5E5",
      borderRadius: 4,
      // minHeight: this.state.matches ? "auto" : 382,
      minHeight: "auto"
    };

    return (
      <>
        <Header />
        <Container>
   
        <Row>
            <Col className="mb-2 mt-2">
              <Col>
                <div className="w-100 d-flex justify-content-between">
                  <h6 class Name="pb-1 d-flex occ-head">Today's Active Client</h6>
                
                </div>
                  <ClientCardRow/>
            
              </Col>
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <Col style={chartContainer} className="rev-col">
                <RevenueChart />
              </Col>
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <Col style={chartContainer}>
                <div className="w-100 d-flex justify-content-between">
                  <h6 className="pb-1 d-flex occ-head">Service Office Report</h6>
                  <div className="d-flex">
                    <Link
                      className="px-1 py-1 btn btn-secondary"
                      style={{ fontSize: 13.5 }}
                      to="/occupancy"
                    >
                      Details <IoEye />
                    </Link>
                  </div>
                </div>
                {/* <OccupancyChart /> */}
              </Col>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Dashboard;
