import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import api from "../../../services/apiCaller";
class ClientCardRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voClients: 0,
      soClients: 0,
    };
    this.userData = JSON.parse(localStorage.getItem("userData"));

  }

  componentDidMount() {
    this.fetchClientData();
  }

  fetchClientData = async () => {
    try {
      // Replace with your actual API endpoint
      const response = await api.get( `partnership/activeClients/${this.userData.data.location.id}` 
    );
      this.setState({
        voClients:response.data.data.voClients,
        soClients: response.data.data.soClients,
      });
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  render() {
    const { voClients, soClients } = this.state;

    return (
      <Row className="mb-2">
        <Col md={4}>
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                <Col xs={3} className="text-center">
                  <FaUserCircle size={29} />
                </Col>
                <Col>
                  <Row>
                    <strong>VO Clients:</strong>
                  </Row>
                  <Row>{voClients}</Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                <Col xs={3} className="text-center">
                  <FaUserCircle size={29} />
                </Col>
                <Col>
                  <Row>
                    <strong>SO Clients:</strong>
                  </Row>
                  <Row>{soClients}</Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default ClientCardRow;
