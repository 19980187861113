import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import api from "../../../services/apiCaller";
import moment from "moment";
import * as numeral from "numeral";
import Spinner from "../../../components/spinner/Spinner";
import { IoEye } from "react-icons/io5";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { jsPDF } from "jspdf";
import "./revenue.css";
const mediaMatch = window.matchMedia("(max-width: 480px)");
//(431, 432, 433, 436, 434, 435, 9043, 9002,9041, 9042, 9040,9039,428,429,430,9038)
class RevenueChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isLoading: true,
      totalRevenue: 0,
      showModal: false,
      detailsData: {},
      filterYear: moment().format("Y"),
      matches: mediaMatch.matches,
    };
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.chartReference = React.createRef();
    this.eventSpaceProducts = [431, 432, 433, 436, 434, 435, 9043, 9002, 9099, 9098, 9082,9098,9099]; //these locations not all products // print,park,call,card
    this.onlyVoPartner = [9042, 9040];
  }

  componentDidMount = () => {
    this._apiCall(this.state.filterYear);

    mediaMatch.addEventListener("resize", (e) => {
      this.state.matches = e.matches;
    });

    return () => {
      mediaMatch.removeEventListener("resize", (e) => {
        this.state.matches = e.matches;
      });
    };
  };

  _apiCall = async (filterYear) => {
    try {
      this.setState({ isLoading: true });
      const year = filterYear ? filterYear.toString() : moment().format("Y");
      const yearDashboard = [year, year - 1];
      const data = await api.get(
        `partnership/revenueDashboard/${this.userData.data.location.id}?year=` + yearDashboard
      );

      this.loadChart(data.data.rows);

      return await this.setState({
        data: data.data,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  handleChangeYear = (year) => {
    this._apiCall(year);
    this.setState({ filterYear: year });
    this.loadChart(this.state.data.rows);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filterYear !== prevState.filterYear) {
      this._apiCall(this.state.filterYear);
    }
  }

  validDateForFilter = (current) => {
    const yearBlocking = moment("2023");
    return current.isAfter(yearBlocking);
  };

  downloadPdf = () => {
    const canvas = document.getElementById("myChart");
    const imgData = canvas.toDataURL("image/png", 1.0);

    let pdf = new jsPDF("landscape");
    pdf.setFontSize(20);
    pdf.addImage(imgData, "JPEG", 10, 20, 280, 150); // (image, type, x, y, width, height)
    pdf.text(13, 13, `Sales Report ${this.state.filterYear}`);
    pdf.save("download.pdf");
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };
  loadChart = (data) => {
    const result = {};
    const eventSpaceProducts = [431, 432, 433, 436, 434, 435, 9043, 9002, 9099, 9098, 9082,9098,9099];
    const onlyVoPartner = [9042, 9040];
    const year = this.state.filterYear ? this.state.filterYear.toString() : moment().format("Y");
    const yearDashboard = [year, year - 1];

    // Initialize result object
    for (let i = 1; i <= 12; i++) {
      result[String(i).padStart(2, '0')] = {
        year: 0,
        secondYear: 0,
      };
    }
    let invoiceother = 0
    let invoiceSOVo = 0
    // Iterate through data and populate result object
    data.forEach(v => {
      if (v.date_paid) {
        const date = v.date_paid.split("-");
        const month = date[1].padStart(2, '0');
        const yearIndex = date[0] === yearDashboard[0] ? 'year' : 'secondYear';

        if (v.invoice_type === 99) {
          if (eventSpaceProducts.includes(this.userData.data.userId)) {
            const eventSpaceString = ["meeting", "desk", "private", "work", "space", "maintenance", "conference", "wanprestasi", "penalty charge"];
            if (eventSpaceString.some(space => v.prod_name.toLowerCase().includes(space))) {
              result[month][yearIndex] += v.product_price;
            } 
          }else if (onlyVoPartner.includes(this.userData.data.userId)) {
            let penaltyString = [
              "wanprestasi",
              "penalty"
            ];
            if (penaltyString.some(space => v.prod_name.toLowerCase().includes(space))) {
              result[month][yearIndex] += v.product_price;
            } 
            return;
          } else if (this.userData.data.userId === 9039) {
            let eventSpaceString = ["desk", "day", "work"];
            if (
              eventSpaceString.some((space) => {
                return v.prod_name.toLowerCase().includes(space);
              })
            ) {
              result[month][yearIndex] += v.product_price;
              if (date[0] == 2023 && month == "01") {

                invoiceother += v.product_price;
              }

            }
          } else if (this.userData.data.userId === 9041) {
            let eventSpaceString = ["wanprestasi"];
            if (
              eventSpaceString.some((space) => {
                return v.prod_name.toLowerCase().includes(space);
              })
            ) {
              result[month][yearIndex] += v.product_price;
              if (date[0] == 2023 && month == "01") {

                invoiceother += v.product_price;
              }

            }
          } else {
            result[month][yearIndex] += v.product_price;

          }
        } else {
          result[month][yearIndex] += v.product_price;
          if (date[0] == 2023 && month == "01") {
            invoiceSOVo += v.product_price;
          }
        }
      }
    });
    // Prepare chart data
    const chartData = {
      labels: moment.monthsShort(),
      datasets: [
        {
          label: yearDashboard[1],
          data: moment.monthsShort().map((month, index) => result[String(index + 1).padStart(2, '0')].secondYear),
          backgroundColor: "rgb(159, 45, 93,0.4)",
          borderColor: "rgba(88, 61, 114, 1)",
        },
        {
          label: yearDashboard[0],
          data: moment.monthsShort().map((month, index) => result[String(index + 1).padStart(2, '0')].year),
          backgroundColor: "rgb(159, 45, 93,0.9)",
          borderColor: "rgba(61, 114, 88, 1)",
        }
      ]
    };


    return (
      <Bar
        ref={this.chartReference}
        id="myChart"
        data={chartData}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  // max: 500000000, // Maximum value set to 500 million
                  stepSize: 100000000, // Step size set to 100 million
                  userCallback: function (value, index, values) {
                    return numeral(value).format("0,0");
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                // Get the dataset label.
                const label = data.datasets[tooltipItem.datasetIndex].label;

                // Format the y-axis value.
                const value =
                  "Rp. " + numeral(tooltipItem.yLabel).format("0,0");

                return `${label}: ${value}`;
              },
            },
          },
          legend: {
            position: 'top',
          },
          plugins: {
            trendline: {
              showLine: true, // Show line
              line: {
                borderColor: 'rgba(255, 0, 0, 0.5)', // Line color
                borderWidth: 2, // Line width
              },
              enableOnGrap: true // Show trendline on top of data points
            },
            datalabels: {
              anchor: (context) => {
                // Atur anchor berdasarkan kondisi tertentu
                const value = context.dataset.label;
                return value % 2 !== 0 ? 'top' : 'end';
              },
              align: (context) => {
                // Atur align berdasarkan kondisi tertentu
                const value = context.dataset.label;
                return value % 2 !== 0 ? 'end' : 'end';
              },
              display: true,
              color: "black",

              formatter: (value, context) => {
                if (value > 0) {
                  let formattedValue;
                  if (value >= 1000000000) {
                    formattedValue = (value / 1000000000).toFixed(3) + ' B';
                  } else if (value >= 1000000) {
                    formattedValue = (value / 1000000).toFixed(3) + ' M';
                  } else {
                    formattedValue = "Rp " + numeral(value).format("0,0");
                  }
                  return formattedValue;
                }
                return "";
              },
              font: {
                weight: "bold",
                size: 8.5,
              },
            },

          },
        }}
      />
    );
  };

  countDetails = (year) => {
    const detailResult = {
      //count items
      voCount: 0,
      voRenewalCount: 0,
      voNewCount: 0,
      //reveneu
      voRevenue: 0,
      voNewRevenue: 0,
      voRenewalReveneu: 0,
      //serviceoffice
      soRevenue: 0,
      soCount: 0,
      otherRevenue: 0,
      otherCount: 0,
    };

    this.state.data.rows.map((v) => {
      if (v.date_paid) {
        const date = v.date_paid.split("-");
        if (year == date[0]) {
          if (v.invoice_type === 5) {
            //vo
            // f += 1;
            detailResult.voRevenue += v.product_price;
            detailResult.voCount += 1;
            if (v.recurring === "y") {
              detailResult.voRenewalCount += 1;
              detailResult.voRenewalReveneu += v.product_price;
            } else {
              detailResult.voNewCount += 1;
              detailResult.voNewRevenue += v.product_price;
            }
          } else if (v.invoice_type === 1) {
            //so
            // f += 1;
            detailResult.soRevenue += v.product_price;
            detailResult.soCount += 1;
          } else {
            if (this.eventSpaceProducts.includes(this.userData.data.userId)) {
              let eventSpaceString = [
                "meeting",
                "desk",
                "private",
                "work",
                "space",
                "conference",
                "maintenance",
                "wanprestasi",
                "penalty charge"
              ];
              if (
                eventSpaceString.some((space) => {
                  return v.prod_name.toLowerCase().includes(space);
                })
              ) {
                detailResult.otherRevenue += v.product_price;
                detailResult.otherCount += 1;
              }
            } else if (this.onlyVoPartner.includes(this.userData.data.userId)) {
              let penaltyString = [
                "wanprestasi",
                "penalty"
              ];
              if (penaltyString.some(space => v.prod_name.toLowerCase().includes(space))) {
                detailResult.otherRevenue += v.product_price;
                detailResult.otherCount += 1;
              } 
              return;
            } else if (this.userData.data.userId === 9039) {
              let eventSpaceString = ["desk", "day", "work"];
              if (
                eventSpaceString.some((space) => {
                  return v.prod_name.toLowerCase().includes(space);
                })
              ) {
                detailResult.otherRevenue += v.product_price;
                detailResult.otherCount += 1;
              }
            } else if (this.userData.data.userId === 9041) {
              let eventSpaceString = ["wanprestasi"];
              if (
                eventSpaceString.some((space) => {
                  return v.prod_name.toLowerCase().includes(space);
                })
              ) {
                detailResult.otherRevenue += v.product_price;
                detailResult.otherCount += 1;
              }
            } else {
              detailResult.otherRevenue += v.product_price;
              detailResult.otherCount += 1;
            }
          }
        }
      }
      return true;
    });
    return detailResult;
  };
  countTotalRevenue = () => {
    let totalYear = 0;
    let totalSecondYear = 0;
    let year = this.state.filterYear;
    let secondYear = year - 1;
    this.state.data.rows.map((v) => {
      if (v.date_paid) {
        const date = v.date_paid.split("-");
        const paidYear = parseInt(date[0]);
        if (paidYear == year) {
          if (v.invoice_type === 99) {
            if (this.eventSpaceProducts.includes(this.userData.data.userId)) {
              let eventSpaceString = [
                "meeting",
                "desk",
                "private",
                "work",
                "space",
                "conference",
                "maintenance",
                "wanprestasi",
                "penalty charge"
              ];
              if (
                eventSpaceString.some((space) => {
                  return v.prod_name.toLowerCase().includes(space);
                })
              ) {
                totalYear += v.product_price;
              }
            } else {
              totalYear += v.product_price;
            }
          } else {
            totalYear += v.product_price;
          }
        } else {
          if (v.invoice_type === 99) {
            if (this.eventSpaceProducts.includes(this.userData.data.userId)) {
              let eventSpaceString = [
                "meeting",
                "desk",
                "private",
                "work",
                "space",
                "conference",
                "maintenance",
                "wanprestasi",
                "penalty charge"
              ];
              if (
                eventSpaceString.some((space) => {
                  return v.prod_name.toLowerCase().includes(space);
                })
              ) {
                totalSecondYear += v.product_price;
              }
            } else {
              totalSecondYear += v.product_price;
            }
          } else {
            totalSecondYear += v.product_price;
          }
        }
      }
      return true;
    });

    return {
      year: numeral(totalYear).format("0,0"),
      secondYear: numeral(totalSecondYear).format("0,0")
    };
  };

  render() {
    return (
      <>
        {this.state.isLoading && <Spinner />}
        <Row>
          <Col>
            <h6 className="pb-1 sales-head">Sales Report</h6>
          </Col>
          <Col>
            <Form>
              <Form.Group
                as={Row}
                controlId="formPlaintextPassword"
                className="justify-content-end d-flex"
              >
                <Form.Label
                  column
                  sm="5"
                  className="justify-content-end d-flex"
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty("padding-top", "0px", "important");
                      if (this.state.matches) {
                        node.style.setProperty(
                          "font-size",
                          "14px",
                          "important"
                        );
                      }
                    }
                  }}
                >
                  Filter Graph By Year
                </Form.Label>
                <Col sm="6">
                  <Datetime
                    className="float-right"
                    initialValue={moment().format("Y")}
                    dateFormat="YYYY"
                    timeFormat={false}
                    isValidDate={this.validDateForFilter}
                    input={true}
                    onChange={(date) => {
                      if (moment(date).isValid()) {
                        return this.handleChangeYear(date.year());
                      }

                      return this.handleChangeYear("2023");
                    }}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <div
          className="d-flex justify-content-end float-right tooltip"
          ref={(node) => {
            if (node) {
              if (this.state.matches) {
                node.style.setProperty(
                  "justify-content",
                  "flex-start",
                  "important"
                );
                node.style.setProperty("float", "left", "important");
              }
            }
          }}
        >
          <Button
            onClick={this.downloadPdf}
            size="sm"
            className="mb-1"
            style={{ fontSize: "0.75rem" }}
          >
            Export chart to PDF
          </Button>
        </div>
        {!this.state.isLoading && this.loadChart(this.state.data.rows)}
        {!this.state.isLoading && (
          <div className="d-flex w-100 justify-content-between">

            <div className="d-block col-5">
              <label
                className="mt-1 mb-0"
                style={{ fontSize: 12, color: "#9C9C9C" }}
              >
                Total Revenue <b>
                  (exclude TAX)
                </b>
              </label><br />
              <h7 className="my-0 revenue-text">
                {this.state.filterYear} :
                Rp. {this.countTotalRevenue().year}
                <br />
                {this.state.filterYear - 1} :
                Rp. {this.countTotalRevenue().secondYear}

              </h7>

              <div style={{ fontSize: 10, color: "#9C9C9C", fontWeight: "bold" }}>
                <p style={{ marginBottom: 0 }}>Disclaimer:</p>
                <ul>
                  <li>
                    All data in this website is preliminary and subject to change.
                  </li>
                  <li>Final calculations and validation are contingent upon the thorough review and approval of our finance team.</li>
                </ul>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {/* <Link className='px-1 py-1 mt-1 btn btn-secondary' style={{ fontSize: 13.5 }} to='/revenue'>
                                Details <IoEye />
                            </Link> */}
              <button
                className="px-1 py-1 mt-1 btn btn-secondary"
                style={{ fontSize: 13.5 }}
                onClick={this.openModal}
              >
                Details <IoEye />
              </button>
            </div>
          </div>
        )}
        {!this.state.isLoading && (
          <Modal
            show={this.state.showModal}
            onHide={this.closeModal}
            centered
            className="custom-modal"
          >
            <Modal.Header
              closeButton
              style={{ backgroundColor: "black", color: "#fff" }}
            >
              <Modal.Title>Revenue Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <style>
                {`
                            .modal-content {
                                max-width: 700px;
                            }
                            .divider {
                            height: 1px;
                            background-color: black;
                            margin: 20px 0;
                            }

                            .content-vo,
                            .content-so {
                            width: 10s0%;
                            }
                          p{
                            margin-bottom:0
                          }

                        `}
              </style>
              <div className="content-vo">
                <h4>Virtual Office</h4>
                <div className="row">
                  <div className="col">
                    <h5>{this.state.filterYear - 1}</h5>
                    <div
                      className="content"
                      style={{ textAlign: "left", padding: "0 0 0 0" }}
                    >
                      <p>New Signups:</p>
                      <ul style={{ paddingLeft: "40px", paddingTop: 0 }}>
                        <li>{this.countDetails(this.state.filterYear - 1).voNewCount} cases</li>
                        <li>
                          IDR{" "}
                          {numeral(this.countDetails(this.state.filterYear - 1).voNewRevenue).format(0, 0)}
                        </li>
                      </ul>
                      <p style={{ marginTop: "20px" }}>Renewals:</p>
                      <ul style={{ paddingLeft: "40px", paddingTop: 0 }}>
                        <li>{this.countDetails(this.state.filterYear - 1).voRenewalCount} cases</li>
                        <li>
                          IDR{" "}
                          {numeral(this.countDetails(this.state.filterYear - 1).voRenewalReveneu).format(
                            0,
                            0
                          )}
                        </li>
                      </ul>
                      <p>Total Revenue:</p>
                      <ul style={{ paddingLeft: "40px", paddingTop: 0 }}>
                        <li>
                          IDR {numeral(this.countDetails(this.state.filterYear - 1).voRevenue).format(0, 0)}
                        </li>
                      </ul>

                    </div>
                  </div>
                  <div className="col">
                    <h5>{this.state.filterYear}</h5>
                    <div
                      className="content"
                      style={{ textAlign: "left", padding: "0 0 0 0" }}
                    >
                      <p>New Signups:</p>
                      <ul style={{ paddingLeft: "40px", paddingTop: 0 }}>
                        <li>{this.countDetails(this.state.filterYear).voNewCount} cases</li>
                        <li>
                          IDR{" "}
                          {numeral(this.countDetails(this.state.filterYear).voNewRevenue).format(0, 0)}
                        </li>
                      </ul>
                      <p style={{ marginTop: "20px" }}>Renewals:</p>
                      <ul style={{ paddingLeft: "40px", paddingTop: 0 }}>
                        <li>{this.countDetails(this.state.filterYear).voRenewalCount} cases</li>
                        <li>
                          IDR{" "}
                          {numeral(this.countDetails(this.state.filterYear).voRenewalReveneu).format(
                            0,
                            0
                          )}
                        </li>
                      </ul>
                      <p>Total Revenue:</p>
                      <ul style={{ paddingLeft: "40px", paddingTop: 0 }}>
                        <li>
                          IDR {numeral(this.countDetails(this.state.filterYear).voRevenue).format(0, 0)}
                        </li>
                      </ul>

                    </div>
                  </div>

                </div>
              </div>
              <div className="divider"></div>
              <div className="content-so">
                <h4>Service Office</h4>
                <div className="content" style={{ textAlign: "left" }}>
                  <div className="row">
                    <div className="col">
                      <h6>{this.state.filterYear - 1}</h6>
                      <ul style={{ paddingLeft: "40px" }}>
                        <li>{this.countDetails(this.state.filterYear - 1).soCount} cases</li>
                        <li>
                          IDR {numeral(this.countDetails(this.state.filterYear - 1).soRevenue).format(0, 0)}
                        </li>
                      </ul>
                    </div>
                    <div className="col">
                      <h6>{this.state.filterYear}</h6>
                      <ul style={{ paddingLeft: "40px" }}>
                        <li>{this.countDetails(this.state.filterYear).soCount} cases</li>
                        <li>
                          IDR {numeral(this.countDetails(this.state.filterYear).soRevenue).format(0, 0)}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="divider"></div>
              <div className="content-so">
                <h4>Other Products</h4>
                <div className="row">
                  <div className="col">
                    <h6>{this.state.filterYear - 1}</h6>
                    <div className="content" style={{ textAlign: "left" }}>
                      <ul style={{ paddingLeft: "40px" }}>
                        <li>{this.countDetails(this.state.filterYear - 1).otherCount} cases</li>
                        <li>
                          IDR{" "}
                          {numeral(this.countDetails(this.state.filterYear - 1).otherRevenue).format(0, 0)}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col">
                    <h6>{this.state.filterYear}</h6>

                    <div className="content" style={{ textAlign: "left" }}>
                      <ul style={{ paddingLeft: "40px" }}>
                        <li>{this.countDetails(this.state.filterYear).otherCount} cases</li>
                        <li>
                          IDR{" "}
                          {numeral(this.countDetails(this.state.filterYear).otherRevenue).format(0, 0)}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                style={{ color: "#fff" }}
                onClick={this.closeModal}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        )}


      </>
    );
  }
}

export default RevenueChart;
