import React, { Component } from "react";
import { logoutService } from "../../services/authServices";
import { FiLogOut, FiLock } from "react-icons/fi";
import { BsPersonFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Select from "react-select";
import api from "../../services/apiCaller";
import "./header.css";
import Logo from "../../assets/img/vox-logo.svg";
import { Col, Container, Nav, Dropdown, Media } from "react-bootstrap";
import Menu from "./Menu";
import eventBus from "../eventBus";

const mediaMatch = window.matchMedia("(max-width: 480px)");
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      menuDisplay: "none",
      centreName: "",
      centreCity: "",
      locations: [],
      matches: mediaMatch.matches,
    };
    this.userData = JSON.parse(localStorage.getItem("userData"));
  }

  logout = () => {
    const doLogout = logoutService();

    if (doLogout.error) {
      this.setState({
        errorMessage: doLogout.response.data.message,
      });
      return false;
    } else {
      return (window.location.href = "/login");
    }
  };

  changeLocation = (id) => {
    let newArr = this.state.locations.filter((location) => {
      return location.id === id.value;
    });
    let newUserData = {
      data: {
        email: this.userData.data.email,
        location: newArr[0],
        name: this.userData.data.name,
        userId: this.userData.data.userId,
      },
      token: localStorage.getItem("token"),
    };

    eventBus.emit("locationChanged", id);
    window.localStorage.setItem("userData", JSON.stringify(newUserData));
    window.dispatchEvent(new Event("storage"));
    window.location.reload();
  };

  fetchAllLocations = async () => {
    try {
      const data = await api.get("partnership/locations", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      data.data.push({
        id : 99999,
        name: "All Location"
      })
      return this.setState({
        locations: data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    const userLocation = String(this.userData.data.location.name).split(",");
    this.setState({
      centreName: userLocation[0],
      centreCity: userLocation[1],
    });

    this.fetchAllLocations();

    mediaMatch.addEventListener("resize", (e) => {
      this.state.matches = e.matches;
    });

    return () => {
      mediaMatch.removeEventListener("resize", (e) => {
        this.state.matches = e.matches;
      });
    };
  }

  closeMenu = async () => {
    return await this.setState({
      menuDisplay: "none",
    });
  };

  openMenu = async () => {
    return await this.setState({
      menuDisplay: "block",
    });
  };

  render() {
    const menu = this.state.hover ? "show" : "";
    let options = this.state.locations.map((location) => {
      return { value: location.id, label: location.name };
    });
    return (
      <>
        <header className="admin-header" id="navHeader">
          <Container className="d-flex h-100">
            <nav className="mr-auto my-auto">
              <Nav className="align-items-center">
                <Nav.Item className="d-none d-lg-inline">
                  <Nav.Link href="foo">
                    <img alt="logo" src={Logo} width="100px" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Media>
                    <div className="avatar avatar-sm avatar-online avatar-city">
                      <span className="avatar-title rounded-circle bg-secondary">
                        {this.state.centreName.charAt(0) +
                          (this.state.centreName.indexOf(" ") > 0
                            ? this.state.centreName.charAt(
                                this.state.centreName.indexOf(" ") + 1
                              )
                            : "")}
                      </span>
                    </div>
                    <Media.Body>
                      <p className="font-weight-bold mb-0">
                        {this.state.centreName}
                      </p>
                      <p className="mb-0 small font-weight-light text-secondary font-italic city-text d-none d-lg-inline">
                        {this.state.centreCity}
                      </p>
                    </Media.Body>
                  </Media>
                </Nav.Item>
              </Nav>
            </nav>

            {/* ! Change Location */}
            {this.userData.data.userId == 9034 ? (
              <nav className="mx-auto location-item">
                <Nav className="nav align-items-center">
                  <div className="nav-item pt-2" style={{ width: "300px" }}>
                    <Select
                      name="dropdown"
                      isClearable={true}
                      isSearchable={true}
                      options={options}
                      defaultValue={options[0]}
                      onChange={this.changeLocation}
                      placeholder="Select Location"
                    />
                  </div>
                </Nav>
              </nav>
            ) : null}
            {/* ! Change Location End */}

            <nav className="ml-auto">
              <Nav className="nav align-items-center">
                <Dropdown alignRight={true} className="nav-item">
                  <Dropdown.Toggle
                    variant="link"
                    className="nav-link no-arrow px-0"
                    data-toggle="dropdown"
                  >
                    <span className="text-secondary mr-1 d-md-inline d-none hi-text">
                      Hi, <strong>{String("Partner vOffice")}</strong>
                    </span>
                    <div
                      className="avatar avatar-sm avatar-online"
                      style={
                        this.state.matches
                          ? { display: "flex", justifyContent: "flex-end" }
                          : {}
                      }
                    >
                      <span className="avatar-title rounded-circle bg-dark text-uppercase">
                        {String(this.userData.data.name).charAt(0)}
                      </span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="triangle-right">
                    <div
                      className="d-flex"
                      style={{ padding: "0.25rem 1.5rem" }}
                    >
                      <div>
                        <div className="avatar avatar-sm avatar-online">
                          <span className="avatar-title rounded-circle bg-dark text-uppercase">
                            {String(this.userData.data.name).charAt(0)}
                          </span>
                        </div>
                      </div>
                      <div>
                        <span className="font-weight-bold">
                          {this.userData.data.name}
                          <br />
                        </span>
                        <span>{this.userData.data.email}</span>
                      </div>
                    </div>
                    <div className="dropdown-divider"></div>
                    <Link to="/change-password" className="dropdown-item">
                      <span style={{ fontSize: 18, marginRight: 8 }}>
                        <FiLock />
                      </span>
                      Change Password
                    </Link>
                    <Dropdown.Item
                      className="dropdown-item"
                      href="foo"
                      onClick={this.logout}
                    >
                      <span style={{ fontSize: 18, marginRight: 8 }}>
                        <FiLogOut />
                      </span>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </nav>
          </Container>
          <div
            className={"collapse navbar-collapse py-3 bg-white"}
            id="collapseSearch"
            data-parent="#navHeader"
          >
            <Container>
              <input
                type="search"
                className="form-control"
                placeholder="Search client here"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </Container>
          </div>
          <div
            style={{ display: this.state.menuDisplay }}
            onMouseLeave={this.closeMenu}
            className={"collapse navbar-collapse py-3 bg-white " + menu}
            id="collapseMenu"
            data-parent="#navHeader"
          >
            <Container className="row mx-auto">
              <Col xs={4} md={2} className="p-0">
                <Dropdown.Item className="dropdown-item text-center" href="#">
                  <span className="d-block" style={{ fontSize: 26 }}>
                    <BsPersonFill />
                  </span>
                  <span style={{ fontSize: 12 }}>Manage Client</span>
                </Dropdown.Item>
              </Col>
              <Col xs={4} md={2} className="p-0">
                <Dropdown.Item className="dropdown-item text-center" href="#">
                  <span className="d-block" style={{ fontSize: 26 }}>
                    <BsPersonFill />
                  </span>
                  <span style={{ fontSize: 12 }}>Manage Client</span>
                </Dropdown.Item>
              </Col>
              <Col xs={4} md={2} className="p-0">
                <Dropdown.Item className="dropdown-item text-center" href="#">
                  <span className="d-block" style={{ fontSize: 26 }}>
                    <BsPersonFill />
                  </span>
                  <span style={{ fontSize: 12 }}>Manage Client</span>
                </Dropdown.Item>
              </Col>
              <Col xs={4} md={2} className="p-0">
                <Dropdown.Item className="dropdown-item text-center" href="#">
                  <span className="d-block" style={{ fontSize: 26 }}>
                    <BsPersonFill />
                  </span>
                  <span style={{ fontSize: 12 }}>Manage Client</span>
                </Dropdown.Item>
              </Col>
              <Col xs={4} md={2} className="p-0">
                <Dropdown.Item className="dropdown-item text-center" href="#">
                  <span className="d-block" style={{ fontSize: 26 }}>
                    <BsPersonFill />
                  </span>
                  <span style={{ fontSize: 12 }}>Manage Client</span>
                </Dropdown.Item>
              </Col>
              <Col xs={4} md={2} className="p-0">
                <Dropdown.Item className="dropdown-item text-center" href="#">
                  <span className="d-block" style={{ fontSize: 26 }}>
                    <BsPersonFill />
                  </span>
                  <span style={{ fontSize: 12 }}>Manage Client</span>
                </Dropdown.Item>
              </Col>
              <Col xs={4} md={2} className="p-0">
                <Dropdown.Item className="dropdown-item text-center" href="#">
                  <span className="d-block" style={{ fontSize: 26 }}>
                    <BsPersonFill />
                  </span>
                  <span style={{ fontSize: 12 }}>Manage Client</span>
                </Dropdown.Item>
              </Col>
              <Col xs={4} md={2} className="p-0">
                <Dropdown.Item className="dropdown-item text-center" href="#">
                  <span className="d-block" style={{ fontSize: 26 }}>
                    <BsPersonFill />
                  </span>
                  <span style={{ fontSize: 12 }}>Manage Client</span>
                </Dropdown.Item>
              </Col>
            </Container>
          </div>
        </header>
        <Menu />
      </>
    );
  }
}

export default Header;
