import React from 'react';

class ViewInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    console.log(this.props.match.params.invoiceId);
  }

  render() {
    const { invoiceId } = this.props.match.params;
    const isExternalBooking = invoiceId.includes("EB");
    const src = isExternalBooking
      ? `https://vox.myvios.cloud/externalbooking/invoice/invoice/${invoiceId}`
      : `https://vox.myvios.cloud/invoice/invoice/pdf/${invoiceId}`;
    return (
      <>
        <iframe src={src} style={{
          position:'fixed',
          top:0,
          left:0,
          bottom:0,
          right:0,
          width:'100%',
          height:'100%',
          border:'none',
          margin:0,
          padding:0,
          overflow:'hidden'}}>
          Your browser doesn't support iframes
        </iframe>
      </>
    )
  }
}

export default ViewInvoice;