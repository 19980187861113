import api from "../../../services/apiCaller";
import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import * as numeral from "numeral";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import jsPDF from "jspdf";
import { Button } from "react-bootstrap";

const mediaMatch = window.matchMedia("(max-width: 480px)");

class SalesReportCharts extends Component {
  constructor(props) {
    super(props);
    this.userData = JSON.parse(localStorage.getItem("userData")).data;
    this.chartReference = React.createRef();
    this.state = {
      isLoading: true,
      chartData: {},
      matches: mediaMatch.matches,
    };
  }

  componentDidMount = () => {
    this._apiCall();

    mediaMatch.addEventListener("resize", (e) => {
      this.state.matches = e.matches;
    });

    return () =>
      mediaMatch.removeEventListener("resize", (e) => {
        this.state.matches = e.matches;
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.filterYear !== prevProps.filterYear) {
      this._apiCall(this.props.filterYear);
    }
  }

  handleChangeYear = (year) => {
    this._apiCall(year);
  };

  exportChart = (key) => {
    let canvas = document.getElementById(key);
    let dataUrl = canvas.toDataURL("image/png", 1.0);

    let pdf = new jsPDF("landscape");
    pdf.setFontSize(20);
    if (key === "chartCase") {
      pdf.addImage(dataUrl, "PNG", 20, 20, 250, 180);
      pdf.text(13, 13, "Report Sales by Case");
    } else {
      pdf.addImage(dataUrl, "PNG", 20, 20, 250, 180);
      pdf.text(13, 13, "Revenue");
    }
    pdf.save("chart.pdf");
  };

  _apiCall = async (filterYear) => {
    try {
      const year = filterYear ? filterYear.toString() : moment().format("Y");
      const data = await api.get(
        `partnership/revenue/${this.userData.location.id}?year=` + year
      );
      let result = {};
      for (let i = 1; i <= 12; i++) {
        result[`${year}-` + (String(i).length < 2 ? "0" + String(i) : i)] = {
          voCase: 0,
          soCase: 0,
          voAmount: 0,
          soAmount: 0,
          otherCase: 0,
          otherAmount: 0,
          voRenewalAmount: 0,
          voRenewalCase: 0,
        };
      }
      let eventSpaceProducts = [431, 432, 433, 436, 434, 435, 9043, 9002, 9099,9098, 9082,9098,9099]; //these locations not all products // print,park,call,card
      let onlyVoPartner = [ 9042, 9040];

      data.data.rows.map((v,i) => {
        if (v.date_paid) {
          const date = v.date_paid.split("-");
          
          if (v.invoice_type === 5) {
            //vo
            result[`${date[0]}-${date[1]}`].voCase += 1;
            result[`${date[0]}-${date[1]}`].voAmount += v.product_price;
            if (v.recurring === "y") {
              result[`${date[0]}-${date[1]}`].voRenewalCase += 1;
              result[`${date[0]}-${date[1]}`].voRenewalAmount += v.product_price;
            }
          } else if (v.invoice_type === 1) {
            //so
            result[`${date[0]}-${date[1]}`].soCase += 1;
            result[`${date[0]}-${date[1]}`].soAmount += v.product_price;
          } else {
            if (eventSpaceProducts.includes(this.userData.userId)) {
              let eventSpaceString = [
                "meeting",
                "desk",
                "private",
                "work",
                "space",
                "conference",
                "maintenance",
                "wanprestasi",
                "penalty charge"
              ];
              if (
                eventSpaceString.some((space) => {
                  return v.prod_name.toLowerCase().includes(space);
                })
              ) {
                let callProduct = ["call"];
                if (
                  callProduct.some((call) => {
                    return v.prod_name.toLowerCase().includes(call);
                  })
                ) {
                  return;
                } else {
                  result[`${date[0]}-${date[1]}`].otherCase += 1;
                  result[`${date[0]}-${date[1]}`].otherAmount += v.product_price;
                }
              }
            } else if (onlyVoPartner.includes(this.userData.userId)) {
              let penaltyString = [
                "wanprestasi",
                "penalty"
              ];
              if (penaltyString.some(space => v.prod_name.toLowerCase().includes(space))) {
                result[`${date[0]}-${date[1]}`].otherCase += 1;
                  result[`${date[0]}-${date[1]}`].otherAmount += v.product_price;
              } 
              return;
            } else if (this.userData.userId === 9039) {
              let eventSpaceString = ["desk", "day","work"];
              if (
                eventSpaceString.some((space) => {
                  return v.prod_name.toLowerCase().includes(space);
                })
              ) {
                result[`${date[0]}-${date[1]}`].otherCase += 1;
                result[`${date[0]}-${date[1]}`].otherAmount += v.product_price;
              }
            } else if (this.userData.userId === 9041) {
              let eventSpaceString = ["wanprestasi"];
              if (
                eventSpaceString.some((space) => {
                  return v.prod_name.toLowerCase().includes(space);
                })
              ) {
                result[`${date[0]}-${date[1]}`].otherCase += 1;
                result[`${date[0]}-${date[1]}`].otherAmount += v.product_price;
              }
            }  else {
              result[`${date[0]}-${date[1]}`].otherCase += 1;
              result[`${date[0]}-${date[1]}`].otherAmount += v.product_price;
            }
          }
        }
        return true;
      });
      
      return await this.setState({
        isLoading: false,
        chartData: result,
      });
    } catch (err) {
      console.log(err);
    }
  };

  chartCaseData = () => {
    let monthKeys = Object.keys(this.state.chartData);
    //passing data for charts
    let res = {
      months: [],
      voCaseArr: [],
      soCaseArr: [],
      voAmountArr: [],
      soAmountArr: [],
      otherAmountArr: [],
      voRenewalCase: [],
      voRenewalAmount: [],
    };

    Object.values(this.state.chartData).map(function (v, k) {
      let month = monthKeys[k].split("-")[1];
      res.months.push(moment(month, "MM").format("MMMM"));
      res.voCaseArr.push(v.voCase);
      res.soCaseArr.push(v.soCase);
      res.voRenewalCase.push(v.voRenewalCase);
      res.voAmountArr.push(numeral(numeral(v.voAmount).format("0,0")).value());
      res.soAmountArr.push(numeral(numeral(v.soAmount).format("0,0")).value());
      res.otherAmountArr.push(
        numeral(numeral(v.otherAmount).format("0,0")).value()
      );
      return true;
    });

    const currentMonth = moment().format("MMMM");
    let colorVo = [];
    let colorSo = [];
    let colorOther = [];

    res.months.map((v) => {
      if (currentMonth === v) {
        colorVo.push("rgba(88, 61, 114, 1)");
        colorSo.push("rgba(252, 134, 33, 1)");
        colorOther.push("rgba(21, 134, 33, 1)");
      } else {
        colorVo.push("rgba(88, 61, 114, 0.3)");
        colorSo.push("rgba(252, 134, 33, 0.3)");
        colorOther.push("rgba(21, 134, 33, 0.3)");
      }
      return true;
    });

    const dataCase = {
      labels: res.months,
      datasets: [
        {
          label: "Virtual Office",
          data: res.voCaseArr,
          backgroundColor: colorVo,
          renewalData: res.voRenewalCase,
        },
        {
          label: "Serviced Office",
          data: res.soCaseArr,
          backgroundColor: colorSo,
        },
      ],
    };

    const dataRevenue = {
      labels: res.months,
      datasets: [
        {
          label: "Virtual Office",
          data: res.voAmountArr,
          backgroundColor: colorVo,
        },
        {
          label: "Serviced Office",
          data: res.soAmountArr,
          backgroundColor: colorSo,
        },
        {
          label: "Other Products",
          data: res.otherAmountArr,
          backgroundColor: colorOther,
        },
      ],
    };

    let chartCase = (
      <Bar
        ref={this.chartReference}
        data={dataCase}
        id="chartCase"
        options={{
          responsive: true,
          maintainAspectRatio: this.state.matches ? false : true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  userCallback: function (value, index, values) {
                    return value;
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                // Get the dataset label.
                const label = data.datasets[tooltipItem.datasetIndex].label;
                // Format the data values.
                const voCase =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];
                if (label === "Serviced Office") {
                  return `${label}: ${voCase}`;
                }
                const voRenewalCase =
                  data.datasets[tooltipItem.datasetIndex].renewalData[
                    tooltipItem.index
                  ];
                const newCase = voCase - voRenewalCase;

                // // Get the data values for the current tooltip item.

                return `${label}: ${voCase} -> New Case: ${newCase}, Renewal Case: ${voRenewalCase}`;
              },
            },
          },
          legend: {
            position: "bottom",
          },
          plugins: {
            datalabels: {
              anchor:"end",
              display: true,
              color: "black", 
              align: 'top',
              formatter: (value, context) => {
            
                if ( value > 0 ) return value;
                return ""
              },
              font: {
                weight: "bold",
                size: 11,
              },
            },
          },
        }}
      />
    );

    let chartRevenue = (
      <Bar
        ref={this.chartReference}
        data={dataRevenue}
        id="chartRevenue"
        options={{
          responsive: true,
          maintainAspectRatio: this.state.matches ? false : true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stepSize: 50000000,
                  userCallback: function (value, index, values) {
                    return "Rp. " + numeral(value).format("0,0");
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                // Get the dataset label.
                const label = data.datasets[tooltipItem.datasetIndex].label;

                // Format the y-axis value.
                const value =
                  "Rp. " + numeral(tooltipItem.yLabel).format("0,0");

                return `${label}: ${value}`;
              },
            },
          },
          legend: {
            position: "bottom",
          },
          plugins: {
            datalabels: {
              anchor:"end",
              display: false,
              color: "black", 
              align: 'top',
              formatter: (value, context) => {
                const formattedValue = numeral(value / 1000).format("0,0");
                if ( value > 0 ) return "Rp " + formattedValue;
                return ""
              },
              font: {
                weight: "light",
                size: 11,
              },
            },
          },
        }}
      />
    );

    return (
      <Tabs
        defaultActiveKey="chartCase"
        // id="uncontrolled-tab-example"
        className="mb-3 mt-5"
      >
        <Tab eventKey="chartCase" title="Report Sales by Case">
          {this.state.matches ? (
            <div style={{ height: "200px" }}>{chartCase}</div>
          ) : (
            chartCase
          )}
          <Button
            onClick={() => this.exportChart("chartCase")}
            size="sm"
            className="mb-1"
            style={{ fontSize: "0.75rem" }}
          >
            Export chart to PDF
          </Button>
        </Tab>
        <Tab eventKey="chartRevenue" title="Revenue">
          {this.state.matches ? (
            <div style={{ height: "280px" }}>{chartRevenue}</div>
          ) : (
            chartRevenue
          )}
          <Button
            onClick={() => this.exportChart("chartRevenue")}
            size="sm"
            className="mb-1"
            style={{ fontSize: "0.75rem" }}
          >
            Export chart to PDF
          </Button>
        </Tab>
      </Tabs>
    );
  };

  render() {
    return <>{!this.state.isLoading && this.chartCaseData()}</>;
  }
}

export default SalesReportCharts;
