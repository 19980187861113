import api from "../../../services/apiCaller";
import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import moment, { now } from "moment";
import * as numeral from "numeral";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import jsPDF from "jspdf";
import { Button, Row, Form, Col } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
const mediaMatch = window.matchMedia("(max-width: 480px)");

class RevenueReportCharts extends Component {
  constructor(props) {
    super(props);
    this.userData = JSON.parse(localStorage.getItem("userData")).data;
    this.chartReference = React.createRef();
    this.state = {
      isLoading: true,
      chartData: {},
      matches: mediaMatch.matches,
      filteredYear : moment().format("Y")
    };
  }

  componentDidMount = () => {
    this._apiCall();

    mediaMatch.addEventListener("resize", (e) => {
      this.state.matches = e.matches;
    });

    return () =>
      mediaMatch.removeEventListener("resize", (e) => {
        this.state.matches = e.matches;
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.filterYear !== prevProps.filterYear) {
      this._apiCall(this.props.filterYear);
    }
  }
  validDateForFilter = (current) => {
    const yearBlocking = moment("2023");
    return current.isAfter(yearBlocking);
  };

  handleChangeYear = (year) => {
    this._apiCall(year);
    this.setState({filteredYear:year});
  };

  exportChart = (key) => {
    let canvas = document.getElementById(key);
    let dataUrl = canvas.toDataURL("image/png", 1.0);

    let pdf = new jsPDF("landscape");
    pdf.setFontSize(20);
    if (key === "chartCase") {
      pdf.addImage(dataUrl, "PNG", 20, 20, 250, 180);
      pdf.text(13, 13, "Report Sales by Case");
    } else {
      pdf.addImage(dataUrl, "PNG", 20, 20, 250, 180);
      pdf.text(13, 13, "Revenue");
    }
    pdf.save("chart.pdf");
  };

  _apiCall = async (filterYear) => {
    try {
      const year = filterYear ? filterYear.toString() : moment().format("Y");
      const data = await api.get(
        `partnership/share/${this.userData.userId}?year=` + year
      );
      let result = {};
      for (let i = 1; i <= 12; i++) {
        result[`${year}-` + (String(i).length < 2 ? "0" + String(i) : i)] = {
          profitShareAmount: 0
        };
      }
      data.data.rows.map((v, i) => {
        if (v.date) {
          const date = v.date.split("-");

          result[`${date[0]}-${date[1]}`].profitShareAmount += v.profitshare;
        }
        return true;
      });

      return await this.setState({
        isLoading: false,
        chartData: result,
      });
    } catch (err) {
      console.log(err);
    }
  };
  countTotalShare = () => {
    let total = 0
    Object.values(this.state.chartData).map(function(v,k){
      total+=v.profitShareAmount
      return true
    })
    return total
  };
  chartCaseData = () => {
    let monthKeys = Object.keys(this.state.chartData);
    //passing data for charts
    let res = {
      months: [],
      profitShareAmountArr: []
    };

    Object.values(this.state.chartData).map(function (v, k) {
      let month = monthKeys[k].split("-")[1];
      res.months.push(moment(month, "MM").format("MMMM"));
      res.profitShareAmountArr.push(numeral(numeral(v.profitShareAmount).format("0,0")).value());
      return true;
    });

    const currentMonth = moment().format("MMMM");
    let colorProfit = [];

    res.months.map((v) => {
      if (currentMonth === v) {
        colorProfit.push("rgba(88, 61, 114, 1)");
      } else {
        colorProfit.push("rgba(88, 61, 114, 0.3)");
      }
      return true;
    });

    const dataCase = {
      labels: res.months,
      datasets: [
        {
          label: "Profit Share",
          data: res.profitShareAmountArr,
          backgroundColor: colorProfit,
        }
      ],
    };



    let chartCase = (
      <Bar
        // ref={this.chartReference}
        data={dataCase}
        id="chartCase"
        options={{
          responsive: true,
          maintainAspectRatio: this.state.matches ? false : true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stepSize: 100000000,
                  userCallback: function (value, index, values) {
                    return "Rp. " + numeral(value).format("0,0");
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label;
                // Format the y-axis value.
                const value =
                  "Rp. " + numeral(tooltipItem.yLabel).format("0,0");

                return `${label}: ${value}`;
              },
            },
          },
          legend: {
            position: "bottom",
          },
          plugins: {
            datalabels: {
              anchor: "end",
              display: true,
              color: "black",
              align: 'top',
              formatter: (value, context) => {

                const formattedValue = numeral(value).format("0,0");
                if (value > 0) return "Rp " + formattedValue;
                return ""
              },
              font: {
                weight: "bold",
                size: 11,
              },
            },
          },
        }}
      />
    );


    return (
      <div>
        <Row>
          <Col>
            <h6 className="pb-1 sales-head"> Net Income Shared </h6>
          </Col>
          <Col>
            <Form>
              <Form.Group
                as={Row}
                controlId="formPlaintextPassword"
                className="justify-content-end d-flex"
              >
                <Form.Label
                  column
                  sm="5"
                  className="justify-content-end d-flex"
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty("padding-top", "0px", "important");
                      if (this.state.matches) {
                        node.style.setProperty(
                          "font-size",
                          "14px",
                          "important"
                        );
                      }
                    }
                  }}
                >
                  Filter Graph By Year
                </Form.Label>
                <Col sm="6">
                  <Datetime
                    className="float-right"
                    initialValue={moment().format("Y")}
                    dateFormat="YYYY"
                    timeFormat={false}
                    isValidDate={this.validDateForFilter}
                    input={true}
                    onChange={(date) => {
                      if (moment(date).isValid()) {
                        return this.handleChangeYear(date.year());
                      }

                      return this.handleChangeYear("2024");
                    }}
                  />
                  <Button
                    onClick={() => this.exportChart("chartCase")}
                    size="sm"
                    className="d-flex justify-content-end float-right tooltip"
                    style={{ fontSize: "0.75rem" , marginTop:"10px"}}
                  >
                    Export chart to PDF
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Row>

        {this.state.matches ? (
          <div style={{ height: "200px" }}>{chartCase}</div>
        ) : (
          chartCase
        )}
        <div>
        <div>
        <label
                className="mt-1 mb-0"
                style={{ fontSize: 12, color: "#9C9C9C" }}
              >
                Total Shared <b>
                  
                </b>
              </label><br />
              <h7 className="my-0 revenue-text">
                {this.state.filteredYear} :
                Rp. {numeral(this.countTotalShare()).format(0, 0)}
                <br />
              
                </h7>

        </div>
        <div style={{ fontSize: 10, color: "#9C9C9C", fontWeight: "bold" , width:"500px"}} 
 >
          <p style={{ marginBottom: 0 }}>Disclaimer:</p>
          <ul>
            <li>
              The profit sharing above is determined based on calculations adjusted to include the agreed-upon expenses, including taxes, as stipulated in the collaboration agreement.
            </li>
          </ul>
        </div>

        </div>
      </div>
    );
  };

  render() {
    return <>{!this.state.isLoading && this.chartCaseData()}</>;
  }
}

export default RevenueReportCharts;
