import React, { Component } from 'react';
import Text from './../../components/text';
import {Button, Alert, Card} from 'react-bootstrap';
import {Input} from '../../components/form/Form';
import BgLogin from './../../assets/img/bg-login.svg';
import Logo from './../../assets/img/vox-logo.svg';

import {FiLogIn, FiAlertCircle} from 'react-icons/fi';
import { loginService } from '../../services/authServices';

class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            errorMessage: '',
            error: false
        }
    }

    getGreeting = () => {
        var e = new Date().getHours();

        return e < 12
        ? "Good Morning"
        : e < 18
        ? "Good Afternoon"
        : "Good Evening";
    }

    handleChange = (e) => {
        this.setState({
            [e.target.type]:e.target.value
        })
    }
    
    handleLogin = async (e) => {
        e.preventDefault();
        const doLogin = await loginService(this.state.email, this.state.password);

        if(doLogin.error) {
            this.setState({
                errorMessage: doLogin.response.response.data.message,
                error: true
            })
            return false;
        } else {
            return (window.location.href = '/dashboard');
        }
        
    }

    handleForgot = async (e) => {
        e.preventDefault();

        return (window.location.href = '/forgot-password');
    }

    handleAlert = ( style, message ) => {
        return <Alert variant="warning" className={style}>{message}</Alert>
    }
    
    render() {
        const bgLogin = {
            background: 'url(' + BgLogin + ') no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            boxShadow: 'inset 0px 4px 15px rgba(0, 0, 0, 0.25)'
        }

        // const forgotPass = {
        //     marginLeft: '-18px',
        // }


        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-7 d-none d-md-block bg-cover bg-primary" style={bgLogin}>
    
                    </div>
                    <div className="col-lg-5">
                        <div className="row align-items-center min-vh-100 bg-white">
                            <div className="col-md-7 col-lg-8 mx-auto">
                                <img alt="logo_vox" src={Logo} className="pb-4 mb-1" />
                                <h4 className="mb-0">
                                    {this.getGreeting()},
                                </h4>
                                <Text className='text-secondary'>Please login to your account</Text>
                                <div className="mt-4">
                                    <Input 
                                        type="email"
                                        label="Email"
                                        formName="email"
                                        className={(this.state.error ? 'is-invalid' : '')}
                                        value={this.state.email}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                    <Input
                                        type="password"
                                        label="Password"
                                        formName="password"
                                        className={(this.state.error ? 'is-invalid' : '')}
                                        value={this.state.password}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                    <div>
                                        { this.state.errorMessage ? this.handleAlert( "d-block",this.state.errorMessage ) : this.handleAlert("d-none") }
                                    </div>
                                    <div className="d-flex justify-content-between mt-5">
                                        {/* <Button variant='link' style={forgotPass} onClick={(e) => this.handleForgot(e)}>
                                            Forgot password?
                                        </Button> */}
                                        <Button variant='primary' onClick={(e) => this.handleLogin(e)}>
                                            <FiLogIn /> Login
                                        </Button>
                                    </div>
                                    <div className="text-center mt-7">
                                        <Card.Text className="small text-secondary"> 
                                            <FiAlertCircle /> &nbsp;
                                            <Text>
                                                Having trouble with login?
                                            </Text>
                                            <a href="https://api.whatsapp.com/send?phone=6285810521417&text=Halo, i need support with vPartner" rel="noreferrer"  variant='link' className='btn-sm small' target="_blank">Ask Us</a> 
                                        </Card.Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;