import React, { Component } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Row, Col } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import Spinner from "../../../components/spinner/Spinner";
import './invoiceViewer.css';

class InvoiceViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            numPages: null,
            pageNumber: 1,
            display: false
        }
    }

    componentDidMount = () => {
        return this.setState({
            display: this.props.display
        })
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        return this.setState({
            numPages: numPages,
            isLoading: false
        })
    }

    closeLightbox = () => {
        return this.setState({
            display: false,
            isLoading: true,
            numPages: null,
            pageNumber: 1
        })
    }

    updatePageNumber = (type) => {
        
        let pageNumber = this.state.pageNumber;

        if (type === 'next') {
            pageNumber++
            if (pageNumber > this.state.numPages) {
                pageNumber = this.state.numPages
            }
        }else if (type === 'previous'){
            pageNumber--
            if (pageNumber < 1) {
                pageNumber = 1
            }
        }

        return this.setState({
            pageNumber: pageNumber
        })
    }

    render() { 
        return (
            <div style={{  
                backgroundColor: 'rgba(0, 0, 0, 0.793)',
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: '100vh',
                zIndex: 9999,
                display: this.state.display ? 'block' : 'none'
            }}>
                <div className='ml-auto' style={{ 
                    position: 'absolute',
                    right: 43,
                    fontSize: 31,
                    top: 9,
                    zIndex: 99999,
                    display: this.state.isLoading ? 'none' : 'block'
                }} >
                    <IoClose onClick={() => { this.closeLightbox(); this.props.handler();}} style={{  
                        color: '#fff'
                    }} />
                </div>
                <Row className="vh-100">
                    <Col style={{
                        textAlign: 'center',
                        display: 'flex'
                    }}>
                        { this.state.isLoading && <Spinner style={{ marginTop: '25vh' }} /> }
                        <div className='mx-auto w-auto d-flex align-self-center'>

                            {/* <Document
                                file={`https://vox.myvios.cloud/invoice/invoice/pdf/${this.props.invoice_id}`}
                                onLoadSuccess={(e) => this.onDocumentLoadSuccess(e)}
                                renderMode='svg'
                            >
                                <Page pageNumber={this.state.pageNumber} />
                            </Document>
                            <p>Page {this.state.pageNumber} of {this.state.numPages}</p>
                            <button onClick={() => this.updatePageNumber('previous')}>Previous</button>
                            <button onClick={() => this.updatePageNumber('next')}>Next</button> */}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default InvoiceViewer;