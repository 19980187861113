import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { Input } from '../../components/form/Form';
import { Button, Form, FormGroup } from 'react-bootstrap';
import Logo from '../../assets/img/vox-logo.svg';
import api from '../../services/apiCaller';

import { IoIosArrowBack } from 'react-icons/io';


class ChangePassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            viewPassword: false,
            input: {},
            errors: {},
            // errorMessage: '',
            // error: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changePasswordService = this.changePasswordService.bind(this);

    }

    handleChange = (e) => {
        let input = this.state.input;
        let errors = {};
        input[e.target.name] = e.target.value;
        
        if (typeof input["newPassword"] !== "undefined" && typeof input["confirmNewPassword"] !== "undefined") {
            if (typeof input["newPassword"] !== "undefined" && typeof input["newPassword"] !== "undefined" && input["newPassword"] !== input["confirmNewPassword"]) {
                errors["confirmNewPassword"] = "New password confirmation doesn't match";

                
            }

            this.setState({
                    errors: errors
                })
        }
        
        this.setState({
            input
        })
    }

    handleViewPassword = (e) => {
        this.setState({
            viewPassword: e.target.checked,
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const input = this.state.input;
        const userData = JSON.parse(localStorage.getItem("userData"));

        if (this.validate()) {
            this.changePasswordService(userData.data.userId, input["oldPassword"], input["newPassword"]);
        }

        // if(doChangePassword.error) {
        //     this.setState({
        //         errorMessage: doChangePassword.response.data.message,
        //         error: true
        //     })
        //     return false;
        // } else {
        //     // return (window.location.href = '/change-password');
        // }
    }

    validate = () => {
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (!input["oldPassword"]) {
            isValid = false;
            errors["oldPassword"] = "Please enter your old password."
        }

        if (!input["newPassword"]) {
            isValid = false;
            errors["newPassword"] = "Please enter your new password."
        }

        if (!input["confirmNewPassword"]) {
            isValid = false;
            errors["confirmNewPassword"] = "Please enter your confirm new password."
        }

        if (typeof input["newPassword"] !== "undefined" && typeof input["confirmNewPassword"] !== "undefined") {
            if (input["newPassword"] !==input["confirmNewPassword"]) {
                isValid = false;
                errors["confirmNewPassword"] = "New password confirmation doesn't match.";
            }
        }

        this.setState({
            errors: errors
        })

        return isValid;
    }

    changePasswordService = async (userId ,oldPassword, newPassword) => {
        try {

            const doChangePassword = await api
                .post(`${process.env.REACT_APP_API_URL}partnership/change-password`,
                {
                    user_id: userId,
                    oldPassword: oldPassword,
                    newPassword: newPassword
                })
            
            if (doChangePassword.status === 200) {
                Swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                }).fire({
                    icon: 'success',
                    title: 'Password changed successfully'
                }).then((res => {
                    if (res) {
                        document.location.replace("/dashboard");
                    }
                }))
            }

            return true;

        } catch (err) {
            this.setState({
                errors: err.response.data
            })
            
            return {
                error: true,
                response: err.response
            }
        }
    }

    render() {
        let formGroup = {
            marginBottom: "2.5rem",
            position: "relative",
        }
        let error = {
            fontSize: 10,
            position: "absolute",
            bottom: -20
        }

        return(
            <div style={{ width: 470 }} className="d-flex mx-auto vh-100">
                <div className="my-auto w-100">

                    <div className="mb-5">
                        <img alt="logo" src={Logo} width="150px" />
                    </div>
                    <h5 className="mb-3">
                        Change Password
                    </h5>
                    <FormGroup style={formGroup}>
                        <Input 
                            type={this.state.viewPassword ? "text" : "password"}
                            label="Old Password"
                            formName="oldPassword"
                            onChange={(e) => this.handleChange(e)}
                        />
                        <div className="text-danger" style={error}>{this.state.errors.oldPassword ? this.state.errors.oldPassword : this.state.errors.message}</div>
                    </FormGroup>
                    <FormGroup style={formGroup}>
                        <Input 
                            type={this.state.viewPassword ? "text" : "password"}
                            label="New Password"
                            formName="newPassword"
                            onChange={(e) => this.handleChange(e)}
                        />
                        <div className="text-danger" style={error}>{this.state.errors.newPassword}</div>
                    </FormGroup>
                    <FormGroup style={formGroup}>
                        <Input 
                            type={this.state.viewPassword ? "text" : "password"}
                            label="Confirm New Password"
                            formName="confirmNewPassword"
                            onKeyUp={(e) => this.handleChange(e)}
                        />
                        <div className="text-danger" style={error}>{this.state.errors.confirmNewPassword}</div>
                    </FormGroup>
                    <Form.Group className="mt-3">
                        <Form.Label className="mb-5" style={{ opacity:0.5 }}>
                            Use numbers and symbols to make your password is more secure
                        </Form.Label>
                        <Form.Check type="checkbox" label="View Password" name="viewPassword" onChange={(e) => this.handleViewPassword(e)} />
                    </Form.Group>
                        
                    <div className="d-flex justify-content-between">
                        <Link
                            className="mt-5"
                            to="/dashboard"
                            >
                                <IoIosArrowBack /> Back to Dashboard
                        </Link>
                        <Button
                            variant='primary'
                            onClick={this.handleSubmit}
                            className="mt-5"
                            >
                                Change
                        </Button>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default ChangePassword;