import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import './App.css';
import Login from './pages/Login';
import ChangePassword from './pages/ChangePassword';
import Dashboard from './pages/Dashboard';
import Occupancy from './pages/Occupancy';
import Revenue from './pages/SalesCenterRevenue';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import Forgot from './pages/ForgotPassword'
import Auth from './Auth';
import RevenueShare from './pages/RevenueShare';
import viewInvoice from './pages/SalesCenterRevenue/viewInvoice';

function App() {

  const isAuthenticated = Auth.isAuthenticated();

  return (
      <Router>
          <Switch>
              <PublicRoute exact path='/login' component={Login} isAuthenticated={isAuthenticated}/>
              <PublicRoute exact path='/forgot-password' component={Forgot} isAuthenticated={isAuthenticated}/>
              <PrivateRoute exact path='/dashboard' component={Dashboard} isAuthenticated={isAuthenticated}/>
              <PrivateRoute exact path='/occupancy' component={Occupancy} isAuthenticated={isAuthenticated}/>
              <PrivateRoute exact path='/sales-center-revenue' component={Revenue} isAuthenticated={isAuthenticated}/>
              <PrivateRoute exact path='/change-password' component={ChangePassword} isAuthenticated={isAuthenticated}/>
              <PrivateRoute exact path='/revenue-share' component={RevenueShare} isAuthenticated={isAuthenticated}/>
              <PrivateRoute exact path='/view-invoice/:invoiceId' component={viewInvoice} isAuthenticated={isAuthenticated}/>
              <Redirect to={isAuthenticated ? '/dashboard' : '/login'} />
          </Switch>
      </Router>
  );
}

export default App;
