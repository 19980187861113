import React, { Component } from 'react';
import { Table, Form, Row, Col, Button, Modal, Badge } from 'react-bootstrap';
import { IoCloseCircle, IoCheckmarkCircle, IoClose } from 'react-icons/io5';
import { FiExternalLink } from 'react-icons/fi'
import "./DataTable.css"
import api from '../../services/apiCaller'
import Spinner from "../../components/spinner/Spinner"
import * as numeral from "numeral";
import * as moment from 'moment';


class DataTable extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isLoadingTable: true,
            tableLimit: 10,
            currentPage: 1,
            totalPage: 1,
            firstPage: true,
            lastPage: false,
            tableData: {},
            statusRoom: {},
            max: 0,
            modal: {
                show: false,
                data: {}
            },
        }

    }

    componentDidMount = () => {
        this._callApi()
    }

    _callApi = async () => {
        await this.setState({
            isLoadingTable: true
        });

        const result = await api.get(this.props.apiTarget, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params: {
                page: this.state.currentPage,
                limit: this.state.tableLimit
            }
        });


        this.setState({
            isLoadingTable: false,
            totalData: result.data.total,
            tableData: result.data,
            totalPage: result.data.totalPage,
            currentPage: result.data.currentPage
        })
    }

    showTableData = () => {
        let raw = [];

        (this.state.tableData.data).map((v, i) => {
            const Td = [];
            (this.props.attributes).map((aV, aI) => {

                let value = <td key={aI} style={{ verticalAlign: "middle", fontSize: 14 }}>{aV.transform ? aV.transform(v[aV.name], aI) : v[aV.name]}</td>
                
                if(aI === 2){
                    value = <td key={aI} style={{ verticalAlign: "middle", fontSize: 20 }}>{aV.transform ? aV.transform(v[aV.name], aI) : v[aV.name]}</td>
                }

                if(aI === 5){
                    value = <td key={aI} style={{ verticalAlign: "middle", textAlign: "right" }}><Button variant={"info"} onClick={() => this.showModalData(v.room_id)} style={{ fontSize: 14}}><FiExternalLink/> More</Button></td>
                }

                return Td.push(
                    value
                )
            })
                        
            return raw.push(<tr style={{ cursor: "pointer" }} key={i}>
                {Td}
            </tr>)
        })

        return raw;
    }

    parseHeaderColumn = () => {
        const headerData = [];

        (this.props.attributes).map((v, i) => {
            return headerData.push(<th key={i} style={{ fontSize: 14 }}>{v.label}</th>)
        });

        return (<tr>
            {headerData}
        </tr>);
    }

    parsePagination = () => {
        const options = [];
        for (let i = 1; i <= this.state.totalPage; i++) {
            options.push(<option>{i}</option>);
        }

        return options;
    }

    onPageChanged = async (e) => {
        await this.setState({
            currentPage: e.target.value
        })

        return this._callApi()
    }

    showModalData = async (roomId) => {
        const result = await api.get(this.props.apiTarget + "/" + roomId, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        this.setState({
            modal: {show: true, data: result.data}
        })

    }


    render() { 
        const statusData = {0:{text:'empty', color: 'secondary'}, 1:{text:'booked', color: 'warning'}, 2:{text:'occupied', color: 'success'}, 3:{text:'occupied', color: 'success'}, 4:{text:'occupied', color: 'success'}, 5:{text:'occupied', color: 'success'}, 6:{text:'disabled', color: 'light'}};
        
        return (
            <div className="py-5" style={{ overflow: "visible" }}>
                <Row>
                    <Col className="px-0 mx-2" style={{ overflow: "auto" }}>
                        {this.state.isLoadingTable ? 
                        <Spinner /> : 
                        <Table>
                            <thead>
                                {this.parseHeaderColumn()}
                            </thead>
                            <tbody>
                                {this.showTableData()}
                            </tbody>
                        </Table>}
                    </Col>
                </Row>
                <Row className='justify-content-end align-content-center' style={{ fontSize: 13, opacity: ".6" }}>
                    <Col xs={12} className=''>
                        <Row className="justify-content-md-end justify-content-center">
                                <span 
                                className="pagination-label my-auto d-md-block d-none">
                                    Rows per page:
                                </span>
                                <div className="test my-auto d-md-block d-none" style={{ 
                                        width: 44,
                                        height:22, }}>
                                    <Form.Control as='select'
                                    className="select-pagination-row py-0 my-auto"
                                    style={{  
                                        width: "auto",
                                        height: 22,
                                        fontSize: 13,
                                        background: "white",
                                        border:0
                                    }} onChange={async (e) => {
                                        await this.setState({
                                            tableLimit: e.target.value,
                                            dataTo: e.target.value,
                                            currentPage: 1,
                                            dataFrom: 1
                                        });

                                        this._callApi()
                                    }} value={this.state.tableLimit}>
                                        <option>10</option>
                                        <option>15</option>
                                        <option>25</option>
                                        <option>35</option>
                                        <option>50</option>
                                    </Form.Control>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path d="M7 10l5 5 5-5z"></path>
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                    </svg>
                                </div>
                            
                                <span className="my-auto d-md-block d-none mx-3">
                                {(this.state.tableData.currentPage * this.state.tableData.limit) - this.state.tableData.limit + 1} - {
                                    this.state.tableData.currentPage * this.state.tableData.limit > this.state.tableData.total 
                                    ? this.state.tableData.total
                                    : this.state.tableData.currentPage * this.state.tableData.limit
                                } of {
                                    this.state.tableData.total
                                }
                                </span>
                            
                                <div className='d-flex align-items-center'>
                                    {/* Button skipPrev Data */}
                                    <Button variant="white" className="rounded-circle p-0 bg-white" style={{ width: 40, height: 40, border: 0 }}
                                    disabled={this.state.currentPage - 1 === 0 ? true : false}
                                    onClick={async () => {
                                        const firstPage = this.state.currentPage - this.state.currentPage;

                                        await this.setState({
                                            currentPage: firstPage,
                                        })

                                        return this.onPageChanged({target: {
                                            value: firstPage
                                        }})
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            role="presentation">
                                                <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                <path fill="none" d="M24 24H0V0h24v24z"></path>
                                        </svg>
                                    </Button>
                                    {/* Button Prev Data */}
                                    <Button variant="white" className="rounded-circle p-0 bg-white" style={{ width: 40, height: 40, border: 0 }} 
                                    disabled={this.state.currentPage - 1 === 0 ? true : false}
                                    onClick={async () => {
                                        const previousPage = this.state.currentPage - 1 === 0 ? 1 : this.state.currentPage - 1;

                                        return this.onPageChanged({target: {
                                            value: previousPage
                                        }})
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            role="presentation">
                                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                        </svg>
                                    </Button>

                                    <div className="d-md-none test my-auto" style={{ 
                                            width: 44,
                                            height:22, }}>
                                        <Form.Control as='select'
                                        className="select-pagination-row py-0 my-auto"
                                        style={{  
                                            width: "auto",
                                            height: 22,
                                            fontSize: 13,
                                            background: "white",
                                            border:0
                                        }} onChange={async (e) => {
                                            await this.setState({
                                                tableLimit: e.target.value,
                                                dataTo: e.target.value,
                                                currentPage: 1,
                                                dataFrom: 1
                                            });

                                            this._callApi()
                                        }} value={this.state.tableLimit}>
                                            <option>10</option>
                                            <option>15</option>
                                            <option>25</option>
                                            <option>35</option>
                                            <option>50</option>
                                        </Form.Control>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path d="M7 10l5 5 5-5z"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                        </svg>
                                    </div>

                                    {/* Button Next Data */}
                                    <Button variant="white" className="rounded-circle p-0 bg-white" style={{width: 40, height: 40, border: 0, fontSize: 18 }}
                                    disabled={this.state.currentPage === this.state.totalPage ? true : false}
                                    onClick={async () => {
                                        return this.onPageChanged({target: {
                                            value: this.state.currentPage + 1
                                        }})
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            role="presentation">
                                                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                        </svg>
                                    </Button>
                                    {/* Button skipNext Data */}
                                    <Button variant="white" className="rounded-circle p-0 bg-white" style={{width: 40, height: 40, border: 0, fontSize: 18 }}
                                    disabled={this.state.currentPage === this.state.totalPage ? true : false}
                                    onClick={async () => {
                                        return this.onPageChanged({target: {
                                            value: this.state.totalPage
                                        }})
                                    }}><svg xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            role="presentation">
                                                <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                <path fill="none" d="M0 0h24v24H0V0z"></path>
                                        </svg>
                                    </Button>
                                </div>
                        </Row>
                    </Col>
                </Row>
                
                <Row>
                    <Modal show={this.state.modal.show} centered>
                        <Modal.Header>
                            <Modal.Title className="align-content-between w-100">
                                {this.state.modal.show ? <div className="d-flex justify-content-between">
                                    <span>
                                        Room Detail {this.state.modal.data.room_name}
                                    </span>
                                    <div
                                        className="btn"
                                        onClick={() => this.setState({ modal: { show: false } })}><IoClose />
                                    </div>
                                </div>
                                : ""}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.modal.show ? <div>

                                <Row className="mb-md-4">
                                    <Col md={3} className="mb-md-0 mb-1">
                                        <Form.Label>
                                            Capacity : <br />
                                            <h6 className="font-weight-bold mb-0">
                                                {this.state.modal.data.room_capacity}
                                            </h6>
                                        </Form.Label>
                                    </Col>
                                    <Col md={3} className="mb-md-0 mb-1">
                                        <Form.Label>
                                            Size : <br />
                                            <h6 className="font-weight-bold mb-0">
                                                {this.state.modal.data.room_size}
                                            </h6>
                                        </Form.Label>
                                    </Col>
                                    <Col md={3} className="mb-md-0 mb-1">
                                        <Form.Label>
                                            View : <br />
                                            <h6 className="font-weight-bold mb-0">
                                                {this.state.modal.data.view === "y" ? <IoCheckmarkCircle className='text-success' /> : <IoCloseCircle className='text-primary' />}
                                            </h6>
                                        </Form.Label>
                                    </Col>
                                    <Col md={3} className="mb-md-0 mb-1">
                                        <Form.Label>
                                            Status :  <br />
                                            <Badge className="float-right" pill variant={statusData[this.state.modal.data.status].color}>{statusData[this.state.modal.data.status].text}</Badge>
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row className="mb-md-4">
                                    <Col md={3} className="mb-md-0 mb-1">
                                        <Form.Label>
                                            Client ID : <br />
                                            <h6 className="font-weight-bold mb-0">
                                                {this.state.modal.data.status !== 0 && this.state.modal.data.status !== 1 ? this.state.modal.data.client_id ? "#" + this.state.modal.data.client_id : "-" : "-"}
                                            </h6>
                                        </Form.Label>
                                    </Col>
                                    <Col md={3} className="mb-md-0 mb-1">
                                        <Form.Label>
                                            Contract : <br />
                                            <h6 className="font-weight-bold mb-0">
                                                {this.state.modal.data.status !== 0 && this.state.modal.data.status !== 1 ? this.state.modal.data.client_plan ? numeral(this.state.modal.data.client_plan.contract_term).format('0,0')+" Month" : "-" : "-"}
                                            </h6>
                                        </Form.Label>
                                    </Col>
                                    <Col md={3} className="mb-md-0 mb-1">
                                        <Form.Label>
                                            Start Date : <br />
                                            <h6 className="font-weight-bold mb-0">
                                                {this.state.modal.data.status !== 0 && this.state.modal.data.status !== 1 ? this.state.modal.data.client_plan ? moment(this.state.modal.data.client_plan.start_date).format('DD MMM YYYY') : "-" : "-"}
                                            </h6>
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row className="mb-md-4">
                                    <Col md={3} className="mb-md-0 mb-1">
                                        <Form.Label>
                                            Last Renew Date : <br />
                                            <h6 className="font-weight-bold mb-0">
                                                {this.state.modal.data.status !== 0 && this.state.modal.data.status !== 1 ? this.state.modal.data.client_plan ? this.state.modal.data.client_plan.last_renew_date ? moment(this.state.modal.data.client_plan.last_renew_date).format('DD MMM YYYY') : "-" : "-" : "-"}
                                            </h6>
                                        </Form.Label>
                                    </Col>
                                    <Col md={3} className="mb-md-0 mb-1">
                                        <Form.Label>
                                            Next Renew Date :  <br />
                                            <h6 className="font-weight-bold mb-0">
                                                {this.state.modal.data.status !== 0 && this.state.modal.data.status !== 1 ? this.state.modal.data.client_plan ? moment(this.state.modal.data.client_plan.next_renew_date).format('DD MMM YYYY') : "-" : "-"}
                                            </h6>
                                        </Form.Label>
                                    </Col>
                                    <Col md={3} className="mb-md-0 mb-1">
                                        <Form.Label>
                                            Lease Price : <br />
                                            <h6 className="font-weight-bold mb-0">
                                                {this.state.modal.data.status !== 0 && this.state.modal.data.status !== 1 ? this.state.modal.data.client_plan ? "IDR" + numeral(this.state.modal.data.lease_price).format('0,0') : "-" : "-"}
                                            </h6>
                                        </Form.Label>
                                    </Col>
                                    <Col md={3} className="mb-md-0 mb-1">
                                        <Form.Label>
                                            Current Price : <br />
                                            <h6 className="font-weight-bold mb-0">
                                                {this.state.modal.data.status !== 0 && this.state.modal.data.status !== 1 ? this.state.modal.data.client_plan ? "IDR" + numeral(this.state.modal.data.client_plan.current_price).format('0,0') : "-" : "-"}
                                            </h6>
                                        </Form.Label>
                                    </Col>
                                </Row>
                            </div> : ''}
                        </Modal.Body>
                    </Modal>
                </Row>
            </div>
        );
    }
}

export default DataTable;