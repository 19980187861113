import React from "react";
import Header from "../../components/header/Header";
import { Container, Badge, Row, Col, Image, Form } from "react-bootstrap";
import { IoCloseCircle, IoCheckmarkCircle } from "react-icons/io5";
import DataTable from "../../components/DataTable/DataTable";
import * as parse from "html-react-parser";
import OccupancyChart from "./charts";
// import OccupancyChart from "../Dashboard/components/occupancy";
import "./charts/occupancy.css";

class Occupancy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        show: false,
        data: {},
      },
    };
  }

  tableAttributes = [
    { label: "Room", name: "room_name" },
    {
      label: "Status",
      name: "status",
      transform: (data) => {
        const statusData = {
          0: { text: "Vacant", color: "secondary" },
          1: { text: "Booked", color: "warning" },
          2: { text: "occupied", color: "success" },
          3: { text: "occupied", color: "success" },
          4: { text: "occupied", color: "success" },
          5: { text: "occupied", color: "success" },
          6: { text: "disabled", color: "light" },
        };
        return (
          <Badge pill variant={statusData[data].color}>
            {statusData[data].text}
          </Badge>
        );
      },
    },
    {
      label: "View",
      name: "view",
      transform: (data, key) => {
        const viewArray = {
          y: <IoCheckmarkCircle key={key} className="text-success" />,
          n: <IoCloseCircle key={key} className="text-primary" />,
        };
        return viewArray[data];
      },
    },
    {
      label: "Size",
      name: "room_size",
      transform: (data) => {
        // return parse(`${data} &#13217;`);
      },
    },
    {
      label: "Capacity",
      name: "room_capacity",
      transform: (data) => {
        return `${data} pax`;
      },
    },
    {
      label: "",
      name: "",
      transform: (data) => {
        return true;
      },
    },
  ];

  render() {
    return (
      <div>
        <Header />
        <Container>
          <Row>
            <Col className="mb-3">
              <h2 className="mt-5 title">Room Occupancy Report</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="mb-3">
              <OccupancyChart className="mb-3" />
            </Col>
            <Col
              xs={12}
              md={{ order: "first" }}
              className="mb-3 align-self-center"
            >
              {JSON.parse(localStorage.userData).data.location.layout ? (
                <Image
                  src={`https://vox.myvios.cloud/location/layout/${
                    JSON.parse(localStorage.userData).data.location.layout
                  }`}
                  fluid
                />
              ) : (
                <Form.Label className="w-100 text-center">
                  No Image Layout
                </Form.Label>
              )}
            </Col>
          </Row>
          <DataTable
            apiTarget="partnership/serviced-offices"
            attributes={this.tableAttributes}
          />
        </Container>
      </div>
    );
  }
}

export default Occupancy;
