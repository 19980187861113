import './form.css';
import React, {useState, useEffect} from 'react';
import generateId from '../utils/generateId';

const Input = props => {
    const [IsFocus, setIsFocus] = useState(false);
    const componentId = generateId('input');

    const inputStatus = IsFocus ? 'active' : '';

    useEffect(() => {
        props.value === '' || props.value === null || props.value === undefined? setIsFocus(false) : setIsFocus(true)
    }, [props.value])

    return (
        <div className={"form-group custom-form " + inputStatus}>

                <label 
                    htmlFor={componentId} 
                    className={"custom-label"}
                >
                    {props.label}
                </label>
                <input 
                    type={props.type}
                    id={componentId}
                    className={`form-control custom-input ${props.className}`} 
                    name={props.formName}
                    value={props.value}
                    onKeyUp={props.onKeyUp}
                    onChange={props.onChange}
                    onFocus={() => setIsFocus(true)}
                    onBlur={
                        event => event.target.value === '' ? setIsFocus(false) : setIsFocus(true)
                    }
                />
                <div className='custom-border-bottom'></div>

            </div>
    )
}

const Textarea = props => {
    const [IsFocus, setIsFocus] = useState(false);
    const componentId = generateId('textarea');

    const inputStatus = IsFocus ? 'active' : '';

    useEffect(() => {
        props.value === '' || props.value === null || props.value === undefined? setIsFocus(false) : setIsFocus(true)
    }, [props.value])

    return (
        <div className={"form-group custom-form " + inputStatus}>

            <label 
                htmlFor={componentId} 
                className={"custom-label"}
            >
                {props.label}
            </label>
            <textarea 
                className={`form-control custom-input ${props.className ? props.className : ''}`} 
                name={props.formName}
                onFocus={() => setIsFocus(true)}
                onBlur={
                    event => event.target.value === '' ? setIsFocus(false) : setIsFocus(true)
                }
                id={componentId}
            ></textarea>
            <div className='custom-border-bottom'></div>

        </div>
    )
}

const Form = props => {
    return(
        <form
            className={props.className}
            onSubmit={props.onSubmit}
        >
            {props.children}
        </form>
    );
}

class Select extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isActive: false
        }
        this.handleActive = this.handleActive.bind(this)
        this.handleNonActive = this.handleNonActive.bind(this)
        this.componentId = generateId('select');
    }

    static Option = ({ children }) => <option value={children.props.value}>{children}</option>;

    handleActive(){
        this.setState({
           isActive: true
        })
    }

    handleNonActive(event){
        if(event.target.value === ''){
            this.setState({
                isActive: false
            })
        }
    }

    render(){
        let inputStatus = this.state.isActive ? 'active' : '';
        return(
            <div className={"form-group custom-form " + inputStatus}>

                <label 
                    htmlFor={this.componentId} 
                    className={"custom-label"}
                >
                    {this.props.label}
                </label>
                <select
                    className={`form-control custom-input ${this.props.className ? this.props.className : ''}`} 
                    name={this.props.formName}
                    onFocus={this.handleActive}
                    onBlur={this.handleNonActive}
                    id={this.componentId}
                >
                    <option className='d-none'></option>
                    {this.props.children}
                </select>
                <div className='custom-border-bottom'></div>

            </div>
        )
    }
}

Input.defaultProps = {
    type: 'text'
}
export {Input, Textarea, Select, Form};
