import React from "react";
import Header from "../../components/header/Header";
import { Container, Badge, Col, Row, Form, Button } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import api from "../../services/apiCaller";
import "./sales-center-revenue.css";
import "./../../assets/css/badge.css";
import * as numeral from "numeral";
import Spinner from "../../components/spinner/Spinner";
import DataTable, { Footer, TotalColumns } from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import SalesReportCharts from "./charts/index";
import InvoiceViewer from "./components/invoiceViewer";
import { IoEyeSharp } from "react-icons/io5";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";

const mediaMatch = window.matchMedia("(max-width: 480px)");

class SalesCenterRevenue extends React.Component {
  constructor(props) {
    super(props);
    this.API_URL = process.env.REACT_APP_API_URL;
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.state = {
      isLoading: true,
      revenueVO: [],
      revenueSO: [],
      revenueOthers: [],
      modalShow: false,
      openedId: null,
      filterYear: moment().format("Y"),
      matches: mediaMatch.matches,
    };

    this.handler = this.handler.bind(this);
  }

  handler() {
    this.setState({
      modalShow: false,
    });
  }

  fetchData = (yearAndMonth) => {
    //Define Identifier
    // Define array
    let dataVO = [];
    let dataSO = [];
    let dataOthers = [];
    /**
     *  List Partner
     *  1. 18-428-partner.gst@voffice.co.id vo-other product 50% so 90%
     *  2. 30-429-partner.gsi@voffice.co.id vo-other product 50% so 85%
     *  3. 76-430-partner.mk@voffice.co.id vo-other product 45% so 85%
     *  4. 95-431-partner.thl@voffice.co.id VO, Meeting Room, SO & Event Space (35%)
     *  5. 96-432-partner.mensana@voffice.co.id  VO, Meeting Room, SO & Event Space (60%)
     *  6. 99-433-partner.prominance@voffice.co.id  VO, Meeting Room, SO & Event Space (40%)
     *  7. 102-434-partner.metropolitan@voffice.co.id VO, Meeting Room (40%)
     *  8. 103-435-partner.gkm@voffice.co.id
     *  9. 98-436-partner.sudirman@voffice.co.id VO, Meeting Room, SO & Event Space (35%)
     *  10. 109- 9002-partner.gelorabraga@voffice.co.id
     */
    const filter = yearAndMonth ? "?yearAndMonth=" + yearAndMonth : "";

    //HTTP Request
    let allOtherProducts = [428, 429, 430, 9038]; // these locations fetch all others product
    let eventSpaceProducts = [431, 432, 433, 436, 434, 435, 9043, 9002, 9099,9098, 9082,9098,9099]; //these locations not all products // print,park,call,card
    let onlyVoPartner = [9042, 9040];
    api
      .get(`/partnership/revenue/${this.userData.data.location.id}` + filter)
      .then((res) => {
        const data = res.data;
        //Loops Every Data and push to array
        data.rows.forEach((value) => {
          if (value.invoice_type === 1) {
            if (
              value.prod_name.toLowerCase().includes("serviced office periode")
            ) {
              value.recurring = "y";
            }
            dataSO.push({
              client_id: value.client_id,
              invoice_id: value.id,
              product: value.prod_name,
              product_price:
                value.currency +
                " " +
                numeral(value.product_price).format("0,0"),
              amount:
                value.currency +
                " " +
                numeral(value.amount_paid).format("0,0") +
                (value.pph_23 === "y" || value.pph_42 === "y" ? "*" : ""),
              date_paid: value.date_paid,
              type: this.showBadge(value.recurring),
              recurring: value.recurring,
            });
          } else if (value.invoice_type === 5) {
            dataVO.push({
              client_id: value.client_id,
              invoice_id: value.id,
              product: value.prod_name,
              amount:
                value.currency +
                " " +
                numeral(value.amount_paid).format("0,0") +
                (value.pph_23 === "y" || value.pph_42 === "y" ? "*" : ""),
              product_price:
                value.currency +
                " " +
                numeral(value.product_price).format("0,0"),
              date_paid: value.date_paid,
              type: this.showBadge(value.recurring),
              recurring: value.recurring,
            });
          } else {
            if (eventSpaceProducts.includes(this.userData.data.userId)) {
              let eventSpaceString = [
                "meeting",
                "desk",
                "private",
                "work",
                "space",
                "conference",
                "maintenance",
                "wanprestasi",
                "penalty charge"
              ];
              if (
                eventSpaceString.some((space) => {
                  return value.prod_name.toLowerCase().includes(space);
                })
              ) {
                let callProduct = ["call"];
                if (
                  callProduct.some((call) => {
                    return value.prod_name.toLowerCase().includes(call);
                  })
                ) {
                  return;
                } else {
                  dataOthers.push({
                    client_id: value.client_id,
                    invoice_id: value.id,
                    product: value.prod_name,
                    amount:
                      value.currency +
                      " " +
                      numeral(value.amount_paid).format("0,0") +
                      (value.pph_23 === "y" || value.pph_42 === "y" ? "*" : ""),
                    product_price:
                      value.currency +
                      " " +
                      numeral(value.product_price).format("0,0"),
                    date_paid: value.date_paid,
                    type: this.showBadge(value.recurring),
                    recurring: value.recurring,
                  });
                }
              }
            } else if (onlyVoPartner.includes(this.userData.data.userId)) {
              let penaltyString = [
                "wanprestasi",
                "penalty"
              ];
              if (penaltyString.some(space => value.prod_name.toLowerCase().includes(space))) {
                dataOthers.push({
                  client_id: value.client_id,
                  invoice_id: value.id,
                  product: value.prod_name,
                  amount:
                    value.currency +
                    " " +
                    numeral(value.amount_paid).format("0,0") +
                    (value.pph_23 === "y" || value.pph_42 === "y" ? "*" : ""),
                  product_price:
                    value.currency +
                    " " +
                    numeral(value.product_price).format("0,0"),
                  date_paid: value.date_paid,
                  type: this.showBadge(value.recurring),
                  recurring: value.recurring,
                });
              } 
              return;
            } else if (this.userData.data.userId === 9039) {
              let eventSpaceString = ["desk", "day", "work"];
              if (
                eventSpaceString.some((space) => {
                  return value.prod_name.toLowerCase().includes(space);
                })
              ) {
                dataOthers.push({
                  client_id: value.client_id,
                  invoice_id: value.id,
                  product: value.prod_name,
                  amount:
                    value.currency +
                    " " +
                    numeral(value.amount_paid).format("0,0") +
                    (value.pph_23 === "y" || value.pph_42 === "y" ? "*" : ""),
                  product_price:
                    value.currency +
                    " " +
                    numeral(value.product_price).format("0,0"),
                  date_paid: value.date_paid,
                  type: this.showBadge(value.recurring),
                  recurring: value.recurring,
                });
              }
            } else if (this.userData.data.userId === 9041) {
              let eventSpaceString = ["wanprestasi"];
              if (
                eventSpaceString.some((space) => {
                  return value.prod_name.toLowerCase().includes(space);
                })
              ) {
                dataOthers.push({
                  client_id: value.client_id,
                  invoice_id: value.id,
                  product: value.prod_name,
                  amount:
                    value.currency +
                    " " +
                    numeral(value.amount_paid).format("0,0") +
                    (value.pph_23 === "y" || value.pph_42 === "y" ? "*" : ""),
                  product_price:
                    value.currency +
                    " " +
                    numeral(value.product_price).format("0,0"),
                  date_paid: value.date_paid,
                  type: this.showBadge(value.recurring),
                  recurring: value.recurring,
                });
              }
            } else {
              dataOthers.push({
                client_id: value.client_id,
                invoice_id: value.id,
                product: value.prod_name,
                amount:
                  value.currency +
                  " " +
                  numeral(value.amount_paid).format("0,0") +
                  (value.pph_23 === "y" || value.pph_42 === "y" ? "*" : ""),
                product_price:
                  value.currency +
                  " " +
                  numeral(value.product_price).format("0,0"),
                date_paid: value.date_paid,
                type: this.showBadge(value.recurring),
                recurring: value.recurring,
              });
            }
          }
        });

        //Save Filtered data to State
        this.setState({
          isLoading: false,
          revenueVO: dataVO,
          revenueSO: dataSO,
          revenueOthers: dataOthers,
          modalShow: false,
        });
      });
  };

  componentDidMount() {
    this.fetchData();

    mediaMatch.addEventListener("resize", (e) => {
      this.state.matches = e.matches;
    });

    return () =>
      mediaMatch.removeEventListener("resize", (e) => {
        this.state.matches = e.matches;
      });
  }

  showBadge = (recurring) => {
    if (recurring === "y") {
      return <Badge variant="secondary">Renewal</Badge>;
    } else {
      return <Badge variant="success">New Sales</Badge>;
    }
  };

  datatableVO = (productCategory) => {
    const columns = [
      {
        name: "CID",
        selector: "client_id",
        width: "120px",
        sortable: true,
      },
      {
        name: "Invoice ID",
        selector: "invoice_id",
        width: "120px",
        sortable: true,
      },
      {
        name: "Product",
        selector: "product",
        sortable: true,
        width: "280px",
        cell: (row) => {
          const productName = row.product;
          const startIndex = productName.indexOf('Suite');
          const trimmedProductName = startIndex !== -1 ? productName.substring(startIndex) : productName;
          return <div title={trimmedProductName}>{trimmedProductName}</div>;
       }
      },
      {
        name: "Product Price",
        selector: "product_price",
        sortable: true,
        width: "140px",
        // width: '400px'
      },
      {
        name: "Amount Paid",
        selector: "amount",
        width: "150px",
        sortable: true,
        cell: (row) => <div title={row.amount}>{row.amount}</div>,
      },
      {
        name: "Type",
        selector: "type",
        sortable: true,
        width: "auto",
      },
      {
        name: "Date Paid",
        selector: "date_paid",
        cell: (props) => moment(props.date_paid).format("DD MMM YYYY"),
        sortable: true,
        width: "140px",
      },
      {
        // cell: row => <Button onClick={() => this.rowClicked(row)} style={{ fontSize: 14 }}><IoEyeSharp /> View PDF</Button>,
        cell: (row) => {
          const linkTo = row.client_id.toString().includes("-EB")
            ? `view-invoice/${row.client_id}`
            : `view-invoice/${row.invoice_id}`;
          // const linkTo = `view-invoice/${row.client_id}`
          if (this.userData.data.location.id == 95) {
            return (
              <Link target="_blank" to={linkTo}>
                <IoEyeSharp /> View PDF
              </Link>
            );

          }
          return ""
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: "150px",
      },
    ];

    if (!this.state.isLoading) {
      let data;
      if (productCategory === "vo") {
        data = this.state.revenueVO;
      } else if (productCategory === "so") {
        data = this.state.revenueSO;
      } else if (productCategory === "others") {
        data = this.state.revenueOthers;
      }
      let productPriceSum = 0;
      let amountPaidSum = 0;

      data.forEach((row) => {
        const productPriceValue = parseFloat(
          row.product_price.replace(/[^\d.-]/g, "")
        );
        const amountPaidValue = parseFloat(row.amount.replace(/[^\d.-]/g, ""));

        if (!isNaN(productPriceValue)) {
          productPriceSum += productPriceValue;
        }

        if (!isNaN(amountPaidValue)) {
          amountPaidSum += amountPaidValue;
        }
      });

      return (
        <div
          className="datatable"
          style={this.state.matches ? { marginTop: "150px" } : {}}
        >
          <div style={{ textAlign: "right" }}>
            <p className="cut-text mt-5">(*) Clients cut their own taxes</p>
          </div>
          <DataTable
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
          <div
            style={{
              textAlign: "right",
              paddingRight: "20px",
              marginBottom: "10rem",
              borderBottom: "1px solid #ccc",
            }}
          >
            <strong style={{ marginRight: "1rem" }} className="total-text">
              Total Product Price:
            </strong>
            <span className="total-text">
              {"IDR " + numeral(productPriceSum).format("0,0")}
            </span>
            <br />
            <strong style={{ marginRight: "1rem" }} className="total-text">
              Total Amount Paid:
            </strong>
            <span className="total-text">
              {"IDR " + numeral(amountPaidSum).format("0,0")}
            </span>
          </div>
        </div>
      );
    }
  };

  rowClicked = (param) => {
    return this.setState({
      modalShow: true,
      openedId: param.invoice_id,
    });
  };

  handleChangeYear = (year) => {
    this.setState({ filterYear: year });
  };

  handleFilterDatatable = (yearAndMonth) => {
    this.fetchData(yearAndMonth);
  };

  validDateForFilter = (current) => {
    const yearBlocking = moment("2023");
    return current.isAfter(yearBlocking);
  };

  exportData = () => {
    this.state.revenueVO.forEach((row) => {
      row.type = !row?.type?.props?.children
        ? row.type
        : row.type.props.children;
      delete row?.recurring;
    });

    this.state.revenueSO.forEach((row) => {
      row.type = !row?.type?.props?.children
        ? row.type
        : row.type.props.children;
      delete row.recurring;
    });

    this.state.revenueOthers.forEach((row) => {
      row.type = !row?.type?.props?.children
        ? row.type
        : row.type.props.children;
      delete row.recurring;
    });

    const workbook = XLSX.utils.book_new();
    const voWorksheet = XLSX.utils.json_to_sheet(this.state.revenueVO);
    const soWorksheet = XLSX.utils.json_to_sheet(this.state.revenueSO);
    const othersWorksheet = XLSX.utils.json_to_sheet(this.state.revenueOthers);

    XLSX.utils.book_append_sheet(workbook, voWorksheet, "Virtual Office");
    XLSX.utils.book_append_sheet(workbook, soWorksheet, "Serviced Office");
    XLSX.utils.book_append_sheet(workbook, othersWorksheet, "Others");
    XLSX.utils.sheet_add_aoa(
      voWorksheet,
      [
        [
          "Client ID",
          "Invoice ID",
          "Product",
          "Amount",
          "Product Price",
          "Date Paid",
          "Type",
        ],
      ],
      { origin: "A1" }
    );
    XLSX.utils.sheet_add_aoa(
      soWorksheet,
      [
        [
          "Client ID",
          "Invoice ID",
          "Product",
          "Amount",
          "Product Price",
          "Date Paid",
          "Type",
        ],
      ],
      { origin: "A1" }
    );
    XLSX.utils.sheet_add_aoa(
      othersWorksheet,
      [
        [
          "Client ID",
          "Invoice ID",
          "Product",
          "Amount",
          "Product Price",
          "Date Paid",
          "Type",
        ],
      ],
      { origin: "A1" }
    );

    XLSX.writeFile(workbook, "revenue-report.xlsx");
  };

  filterComponent = () => {
    return (
      <>
        <Form className="filter-tabs">
          <Form.Group
            as={Row}
            controlId="formPlaintextPassword"
            className="justify-content-end d-flex"
          >
            <Form.Label
              column
              sm="5"
              className={
                this.state.matches
                  ? "justify-content-center d-flex mt-3"
                  : "justify-content-end d-flex"
              }
              style={this.state.matches ? { fontSize: "15px" } : {}}
            >
              Filter Invoices By Month :
            </Form.Label>
            <Col sm="3">
              <Datetime
                dateFormat="MMMM"
                inputProps={{
                  placeholder: `All of ${moment().format("YYYY")}`,
                }}
                timeFormat={false}
                input={true}
                isValidDate={this.validDateForFilter}
                onChange={(date) => {
                  if (moment(date).isValid()) {
                    return this.handleFilterDatatable(
                      moment(date).format("YYYY-MM")
                    );
                  }
                }}
              />
            </Col>
            <Col
              sm="3"
              className={
                this.state.matches ? "justify-content-center d-flex mt-3" : ""
              }
            >
              <Button
                onClick={this.exportData}
                variant="success"
                size={this.state.matches ? "sm" : null}
              >
                Export to Excel
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </>
    );
  };

  render() {
    // const yearBlocking = moment('2021');
    // const valid = function (current) {
    //     return current.isAfter(yearBlocking);
    // };

    const tabContainerStyle = {
      width: "120%",
      marginLeft: "-10%",
      // Add media query for smaller screen sizes
      "@media (maxWidth: 768px)": {
        width: "100%", // Reset width for mobile screens
        marginLeft: "0", // Reset margin for mobile screens
      },
    };

    return (
      <>
        <Header />
        <Container>
          <Row>
            <Col>
              <h2 className="mt-5 rev-text">Revenue Report</h2>
            </Col>
            <Col
              style={{
                marginTop: "2.5rem",
              }}
            >
              <Form>
                <Form.Group
                  as={Row}
                  controlId="formPlaintextPassword"
                  className="justify-content-end d-flex"
                >
                  <Form.Label
                    column
                    sm="5"
                    className="justify-content-end d-flex"
                    ref={(node) => {
                      if (node) {
                        if (this.state.matches) {
                          node.style.setProperty(
                            "font-size",
                            "14px",
                            "important"
                          );
                          node.style.setProperty(
                            "padding-top",
                            "0",
                            "important"
                          );
                        }
                      }
                    }}
                  >
                    Filter Graph By Year :
                  </Form.Label>
                  <Col sm="5">
                    <Datetime
                      className="float-right"
                      initialValue={moment().format("Y")}
                      dateFormat="YYYY"
                      timeFormat={false}
                      isValidDate={this.validDateForFilter}
                      input={true}
                      onChange={(date) => {
                        if (moment(date).isValid()) {
                          return this.handleChangeYear(date.year());
                        }

                        return this.handleChangeYear("2023");
                      }}
                    />
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          {this.state.isLoading && <Spinner></Spinner>}

          {!this.state.isLoading && (
            <SalesReportCharts filterYear={this.state.filterYear} />
          )}

          {!this.state.isLoading && (
            <div style={{ position: "relative", ...tabContainerStyle }}>
              {this.filterComponent()}
              <Tabs
                defaultActiveKey="vo"
                id="uncontrolled-tab-example"
                className={
                  this.state.matches
                    ? "mb-3 mt-5 revenue-tab d-flex justify-content-center"
                    : "mb-3 mt-5 revenue-tab"
                }
              >
                <Tab eventKey="vo" title="Virtual Office" className="mt-5">
                  {!this.state.isLoading ? this.datatableVO("vo") : ""}
                </Tab>
                <Tab eventKey="so" title="Serviced Office">
                  {!this.state.isLoading ? this.datatableVO("so") : ""}
                </Tab>
                <Tab eventKey="others" title="Others">
                  {!this.state.isLoading ? this.datatableVO("others") : ""}
                </Tab>
              </Tabs>
            </div>
          )}
        </Container>
        {this.state.modalShow && (
          <InvoiceViewer
            handler={this.handler}
            invoice_id={this.state.openedId}
            display={this.state.modalShow}
          />
        )}
      </>
    );
  }
}

export default SalesCenterRevenue;
