import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import { FiCalendar } from 'react-icons/fi';
import { Link } from 'react-router-dom';


class Menu extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            date: '',
            pathname: ''
        }
    }

    componentDidMount(){
        //Get today Date
        let today = new Date();
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = months[today.getMonth()];
        let yyyy = today.getFullYear();
        today = dd + ' ' + mm + ' ' + yyyy;        
        //Set today date to state
        this.setState({
            date: today
        })

        //Get URL Path
        let urlPath = window.location.pathname;
        this.setState({
            pathname: urlPath
        }) 
    }

    render(){

        return (
            <>
                <Nav style={{backgroundColor: '#edeef0'}}>
                    <Container>
                        <Nav>
                            <Nav.Item>
                                    <Link className={"nav-link menu-link text-decoration-none" + (this.state.pathname === '/dashboard' ? ' active' : '')} to="/dashboard">
                                        Dashboard
                                    </Link>
                            </Nav.Item>
                            <Nav.Item>
                                    <Link className={"nav-link menu-link text-decoration-none" + (this.state.pathname === '/sales-center-revenue' ? ' active' : '')} to="/sales-center-revenue">
                                        {`Sales & Center Revenue`}
                                    </Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                    <Link className={"nav-link menu-link text-decoration-none" + (this.state.pathname === '/occupancy' ? ' active' : '')} to="/occupancy">
                                        Occupancy
                                    </Link>
                            </Nav.Item> */}
                            <Nav.Item>
                                    <Link className={"nav-link menu-link text-decoration-none" + (this.state.pathname === '/revenue-share' ? ' active' : '')} to="/revenue-share">
                                        Partner Share
                                    </Link>
                            </Nav.Item>
                            <Nav.Item className="nav-link" style={{marginLeft: 'auto'}}>
                                    <FiCalendar style={{verticalAlign: 'baseline', paddingTop: '2px'}} /> {this.state.date}
                            </Nav.Item>
                        </Nav>
                    </Container>
                </Nav>
            </>
        )

    }


}


export default Menu;