import React, { Component } from "react";
import Header from "../../components/header/Header";
import {
  Container,
  Badge,
  Row,
  Col,
  Image,
  Form,
  Button,
  Link,
} from "react-bootstrap";
import { IoEyeSharp } from "react-icons/io5";
import api from "../../services/apiCaller";
import DataTable from "react-data-table-component";
import * as moment from "moment";
import "./index.css";
import RevenueReportCharts from "./charts";
class RevenueShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.userData = JSON.parse(localStorage.getItem("userData"));
  }

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    let url;

    if (this.userData.data.userId == 9034) {
      url = `/partnership/location/reports/${this.userData.data.location.id}`;
    } else {
      url = `/partnership/reports/${this.userData.data.userId}`;
    }

    api
      .get(url)
      .then((res) => {
        let data = [];
        res.data.rows.forEach((v) => {
          v["transferred_at"] = moment(v.transferred_at).format("DD MMM YYYY");
          data = [...data, v];
        });

        this.setState({ ...this.state, data: data, isLoading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  openFile = (row) => {
    // return window.open(`http://vios.test/partner/report/view/${row}`)
    return window.open(`https://vox.myvios.cloud/partner/report/view/${row}`);
  };

  loadDataTable = () => {
    let columns = [
      {
        name: "Period",
        selector: "period",
        width: "30%",
        cell: (props) => moment(props.period).format("MMM YYYY"),
      },

      {
        cell: (row) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "10px 0 10px 0",
              }}
            >
              {row.partner_documents.map((v, index) => (
                <Button
                  highlightOnHover
                  key={index}
                  onClick={() => this.openFile(v.name_file)}
                  variant="light"
                  style={{
                    fontSize: 14,
                    width: `500px`,
                    marginBottom: "5px",
                    backgroundColor: "white",
                    color: "black", // Add some spacing between Buttons,
                    textAlign: "left",
                  }}
                >
                  <IoEyeSharp /> {v.name_file}
                </Button>
              ))}
            </div>
          );
        },
        ignoreRowClick: true,
        allowOverflow: true,
        width: "auto",
        button: true,
        name: "Documents",
      },
    ];

    return (
      <DataTable
        columns={columns}
        data={this.state.data}
        defaultSortField="id"
        defaultSortAsc={false}
        pagination
        highlightOnHover
      />
    );
  };

  render() {
    const chartContainer = {
      padding: 20,
      border: "1px solid #E5E5E5",
      borderRadius: 4,
      // minHeight: this.state.matches ? "auto" : 382,
      minHeight: "auto" 
    };
    return (
      <>
        <Header />
        <Container>
        
          
    
          <Row>
            <Col className="mb-3">
              <h2 className="mt-5 title">Reports</h2>
            </Col>
         
          </Row>
          <Row>
          <Col className="mb-2">
              <Col style={chartContainer} className="rev-col">
                <RevenueReportCharts />
              </Col>
            </Col>
          </Row>
          {!this.state.isLoading && this.loadDataTable()}
        </Container>
      </>
    );
  }
}

export default RevenueShare;
